import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import auth, { authSlice, getLogout } from "../../features/auth";

import {
  IconButton,
  Avatar,
  Box,
  Button,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  Image,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiDisc,
  FiMenu,
  FiChevronDown,
  FiPlus,
} from 'react-icons/fi';
import {
  TbGolf
} from 'react-icons/tb'


const LinkItems = [
  { name: 'Home', icon: FiHome, path: '/' },
  { name: 'Dashboard', icon: FiTrendingUp, path: '/dashboard/' },
  { name: 'Rounds', icon: TbGolf, path: '/rounds/' },
  { name: 'Shot Analyzer', icon: FiDisc, path: '/shots/' },
];

export default function SidebarWithHeader({children}) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useSelector((state) => state.auth);

  return (
    <Box minH='100vh' bg={'highlight.50'}>
      {auth.isLoggedIn
      ? <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      : null
      }
      {auth.isLoggedIn
      ? <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      : null
      }
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: auth.isLoggedIn ? 60 : 0 }} p='4'>
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {

  const navigate = useNavigate();

  return (
    <Box
      transition='3s ease'
      bg={'primary.800'}
      borderRight='1px'
      borderRightColor={'primary.900'}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}>
      <Flex h='20' alignItems='flex-start' mx='7' marginTop={'5'} justifyContent='space-between'>
        <Link onClick={() => navigate('/')}>
          <Image src='/logo.png' />
        </Link>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} color={'gray.100'}/>
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={() => { onClose(); navigate(link.path)}}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {

  return (
    <Link href='#' style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='sm'
        role='group'
        cursor='pointer'
        color={'highlight.50'}
        _hover={{
          bg: 'primary.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr='4'
            fontSize='16'
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth)

  const handleLogOut = async (e) => {
    e.preventDefault();
    dispatch(getLogout({token: auth.token})).then(() => navigate('/login'))  
  }

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
      />

      <Link onClick={() => navigate('/')} display={{base: 'flex', md: 'none'}}>
        <Image src='/logo.png' boxSize={'60px'}/>
      </Link>

      <HStack spacing={{ base: '0', md: '6' }}>
        {auth.isLoggedIn
        
        ? <HStack alignItems={'center'} spacing={4}>
        <Button
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            size='sm'
            leftIcon={<FiPlus />}
            color={'primary.800'}
            bg={'accent.400'}
            onClick={() => navigate('/round/create')}
            _hover={{
              bg: 'accent.500',
              color: 'primary.900'
            }}>
            Add Round
          </Button>
          <Menu>
            <MenuButton
              py={2}
              transition='all 0.3s'
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={'white'}
              borderColor={'white'}>
              <MenuDivider />
              <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        : <HStack alignItems={'center'} spacing={4}>
          <><Button
            display={{ base: 'none', md: 'inline-flex' }}
            as={'a'}
            fontSize={'sm'}
            fontWeight={400}
            variant={'link'}
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
          <Button
            display={{ md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'primary.800'}
            bg={'accent.400'}
            onClick={() => navigate('/signup')}
            _hover={{
              bg: 'accent.500',
              color: 'primary.900'
            }}>
            Sign Up
          </Button></>
        </HStack>
        }
      </HStack>
    </Flex>
  );
};