import { Box, Progress, Text, VStack } from "@chakra-ui/react"

export function PageLoader(props) {

  return <Box>
    <VStack
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      p={10}
      width='100%'
      height={'100%'}
      marginTop={'25%'}
    >
      <Progress isIndeterminate width={'35%'} colorScheme='primary' />
    </VStack>
  </Box>
}

PageLoader.propTypes = {
}

PageLoader.defaultProps = {
}