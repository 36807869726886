import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
  }
});

const routes = {
  rounds: 'round/',
  update_shots: 'shot/bulk_update_hole/'
}

export const deleteARound = async (payload) => {
  const { data } = await instance.delete(
    `${routes.rounds}${payload.round_id}/`,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      }
    }
  )
}

export const getAllRounds = async (token) => {
  const { data } = await instance.get(
    routes.rounds,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${token}`
      }
    }
  );
  return data;
}

export const getARound = async (payload) => {
  const { data } = await instance.get(
    routes.rounds+`${payload.round}/`,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      }
    }
    );
    return data;
}

export const createRound = async (payload) => {
  const { data } = await instance.post(
    routes.rounds,
    {
      user: payload.user_id,
      course: payload.course,
      tee: payload.tee,
      date: payload.date
    },
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      }
    }
  )
  return data;
}

export const updateShots = async (payload) => {
  const {data} = await instance.post(
    routes.update_shots,
    {
      hole: payload.hole,
      round: payload.round,
      shots: payload.shots
    },
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      }
    }
  )
  return data;
}