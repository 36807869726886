import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Flex, Box, FormControl, FormLabel, Input, InputGroup,
  InputRightElement, Stack, Button, Heading,
  Text, Link, Alert, AlertIcon, AlertDescription
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { createNewUser } from '../../features/auth';
import mixpanel from 'mixpanel-browser';
import { SUBMITTED_SIGN_UP } from '../../analytics/events';

export function SignUpCard(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [re_password, setRePassword] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSignUp = (e) => {
    e.preventDefault();

    mixpanel.track(SUBMITTED_SIGN_UP.name)

    dispatch(createNewUser({email, password, re_password}));
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          boxShadow={'lg'}
          p={8}
          minW={'400px'}
          >
          {
            props.showConfirmation
            ? <Stack spacing={4} alignItems='center'>
              <Text fontSize='4xl' textAlign={'center'}>Check your email to complete sign up!</Text>
            </Stack>
            : <Stack spacing={4}>
                {props.errorMessage !== ''
                  ? <Alert status='error'>
                    <AlertIcon />
                    <AlertDescription>{props.error_message}</AlertDescription>
                  </Alert>
                  : null
                }
                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input 
                    type="email" 
                    value={email}
                    onChange={e => setEmail(e.target.value)}  
                  />
                </FormControl>
                <FormControl id="password" isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input 
                      type={showPassword ? 'text' : 'password'} 
                      value={password}  
                      onChange={e => setPassword(e.target.value)}
                    />
                    <InputRightElement h={'full'}>
                      <Button
                        variant={'ghost'}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }>
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl id="re_password" isRequired>
                  <FormLabel>Re-type Password</FormLabel>
                  <InputGroup>
                    <Input 
                      type={showPassword ? 'text' : 'password'} 
                      value={re_password}
                      onChange={e => setRePassword(e.target.value)}
                    />
                    <InputRightElement h={'full'}>
                      <Button
                        variant={'ghost'}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }>
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Stack spacing={10} pt={2}>
                  <Button
                    loadingText="Submitting"
                    isLoading={props.loading}
                    size="lg"
                    bg={'primary.400'}
                    color={'white'}
                    onClick={handleSignUp}
                    _hover={{
                      bg: 'primary.300',
                    }}>
                    Sign up
                  </Button>
                </Stack>
                <Stack pt={6}>
                  <Text align={'center'}>
                    Already a user? <Link color={'blue.400'} onClick={() => navigate('/login')}>Login</Link>
                  </Text>
                </Stack>
              </Stack>
          }
          
        </Box>
      </Stack>
    </Flex>
  );
}

SignUpCard.propTypes = {
  loading: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  errorMessage: PropTypes.string
}

SignUpCard.defaultProps = {
  loading: false,
  showConfirmation: false,
  errorMessage: ''
}