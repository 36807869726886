import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { authSlice } from '../features/auth';
import { blogSlice } from '../features/blog';
import { roundsSlice } from '../features/rounds';
import { coursesSlice } from '../features/courses';
import { statsSlice } from '../features/stats';
import { shotSlice } from '../features/shots';

const logger = createLogger({

})

const reducers = combineReducers({
  auth: authSlice.reducer,
  blog: blogSlice.reducer,
  courses: coursesSlice.reducer,
  rounds: roundsSlice.reducer,
  shots: shotSlice.reducer,
  stats: statsSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: ['persist']
    }
  }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store)