import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import debounce from 'lodash.debounce'

import { getShotFilters, getShots, getShotTypes, createShotFilter } from "./api";

const initialState = {
  status: {    
    shots: 'idle',
    shot_filters: 'idle',
    shot_types: 'idle',
  },
  error: {
    shots: '',
    shot_filters: '',
    shot_types: '',
  },
  shot_types: null,
  shots: [],
  shot_filters: [],
  current: 1,
  next: null,
  previous: null,
  total_shots: 0,
  total_pages: 0,
}

export const getAllShots = createAsyncThunk(
  'shots/getAllShots',
  async (payload) => {
    return getShots(payload.page, payload.filters)
  }
)

export const getAllShotFilters = createAsyncThunk(
  'shots/getAllShotFilters',
  async () => {
    return getShotFilters()
  }
)

export const createAShotFilter = createAsyncThunk(
  'shots/createAShotFilter',
  async (payload) => {
    return createShotFilter(payload)
  }
)

export const getAllShotTypes = createAsyncThunk(
  'shots/getAllShotTypes',
  async () => {
    return getShotTypes()
  }
)

export const shotSlice = createSlice({
  name: 'shots',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getAllShots.pending, (state) => {
        state.status.shots = 'loading'
        state.error.shots = ''
      })
      .addCase(getAllShots.fulfilled, (state, action) => {
        state.status.shots = 'idle'
        state.error.shots = ''
        state.shots = action.payload.results
        state.current = action.payload.current
        state.next = action.payload.next
        state.previous = action.payload.previous
        state.total_shots = action.payload.count
        state.total_pages = Math.ceil(action.payload.last_page / action.payload.results.length)
      })
      .addCase(getAllShots.rejected, (state) => {
        state.status.shots = 'idle'
        state.error.shots = 'unable to get shots'
      })
      .addCase(getAllShotFilters.pending, (state) => {
        state.status.shot_filters = 'loading'
        state.error.shot_filters = ''
      })
      .addCase(getAllShotFilters.fulfilled, (state, action) => {
        state.status.shot_filters = 'idle'
        state.error.shot_filters = ''
        state.shot_filters = action.payload.results
      })
      .addCase(getAllShotFilters.rejected, (state) => {
        state.status.shot_filters = 'idle'
        state.error.shot_filters = 'unable to get shot filters'
      })
      .addCase(createAShotFilter.pending, (state) => {
        state.status.shot_filters = 'loading'
        state.error.shot_filters = ''
      })
      .addCase(createAShotFilter.fulfilled, (state, action) => {
        state.status.shot_filters = 'idle'
        state.error.shot_filters = ''
        state.shot_filters = [...state.shot_filters, action.payload]
      })
      .addCase(createAShotFilter.rejected, (state) => {
        state.status.shot_filters = 'idle'
        state.error.shot_filters = 'unable to create shot filter'
      })
      .addCase(getAllShotTypes.pending, (state) => {
        state.status.shot_types = 'loading'
        state.error.shot_types = ''
      })
      .addCase(getAllShotTypes.fulfilled, (state, action) => {
        state.status.shot_types = 'idle'
        state.error.shot_types = ''
        state.shot_types = action.payload.results
      })
      .addCase(getAllShotTypes.rejected, (state) => {
        state.status.shot_types = 'idle'
        state.error.shot_types = 'unable to get shot types'
      })
  }
})

export default shotSlice.reducer