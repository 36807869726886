import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box, VStack, Heading, HStack, IconButton, Select, Wrap, WrapItem } from "@chakra-ui/react";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import { FiArrowLeft } from "react-icons/fi";

import { RoundForm } from "../components/RoundForm";
import { ShotTypeRadar } from "../components/ShotTypeRadar";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";

import { getCourses } from "../features/courses";
import { getRound } from "../features/rounds";
import { BasicMetric } from "../components/BasicMetric";
import { getSummary } from "../features/stats";
import { PageLoader } from "../components/PageLoader";
import { StatRadar } from "../components/StatRadar";
import { StackedSignChart } from "../components/StackedSignChart";
import { PageContainer } from "../components/PageContainer";

export function ViewRound() {
  
  const page_title = 'View Round'

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dynamically allocate column widths as user resized window
  const column_ref = useRef(0);
  const [col_width, setColWidth] = useState(0);
  useEffect(() => {
    setColWidth(column_ref.current ? column_ref.current.offsetWidth : 0);
    const handleResize = () => {
      setColWidth(column_ref.current.offsetWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  const token = useSelector( state => state.auth.token );
  const summary = useSelector( state => state.stats.summary)
  const { round_id } = useParams()

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])  

  useEffect(() => {
    dispatch(getCourses({ token }))
    dispatch(getRound({ token, round: round_id }))
    dispatch(getSummary({ token }))
  }, [dispatch, token, round_id])

  const [editing_round, toggleEditRound] = useState(false)
  const [timeframe, setTimeframe] = useState('alltime')
  const [aggregation, setAggregation] = useState('avg')

  const renderHeader = () => {

    return <Wrap justifyContent={'space-between'} w='100%' flexWrap={'wrap'}>
      <WrapItem>
        <HStack 
          w='100%' 
          display={'flex'} 
          alignItems='center'
          spacing={4}
        >
          <IconButton 
            variant='outline' 
            icon={<FiArrowLeft />} 
            onClick={() => navigate(-1)}
            colorScheme='gray'
            size={{base: 'sm'}}
          />
          {editing_round
            ? <RoundForm 
              courses={courses}
              course={current_round.course}
              date={current_round.date}
              tee={current_round.tee}
            />
            : <Heading size={{sm: 'xs', md: 'lg'}}>{`${current_round.course.name}  (${current_round.date})`}</Heading>
          }
          <IconButton 
            aria-label={'edit'} 
            variant={'ghost'} 
            icon={editing_round ? <CloseIcon /> : <EditIcon />}
            onClick={() => toggleEditRound(!editing_round)}
            size={'xs'} 
            marginLeft={'10px'}
            display={{base: 'none', md: 'inline-flex'}}   
          />
        </HStack>
      </WrapItem>
      <WrapItem>
        <HStack>
          <Select value={timeframe} onChange={e => setTimeframe(e.target.value)}>
            <option value='alltime'>vs. All Time</option>
            <option value='last4'>vs. Last 4 Rounds</option>
            <option value='last10'>vs. Last 10 Rounds</option>
          </Select>
          <Select value={aggregation} onChange={e => setAggregation(e.target.value)}>
            <option value='avg'>Average</option>
            <option value='med'>Median</option>
            <option value='best'>Best</option>
            <option value='worst'>Worst</option>
          </Select>
        </HStack>
      </WrapItem>
    </Wrap>
  }

  const renderPrimaryMetrics = () => {
    return <Wrap 
      justify={'center'}
    >
      <WrapItem>
        <BasicMetric 
          value={current_round.score}
          label='Score'
          secondaryArrow={
            current_round.score <= summary.score[`${timeframe}_${aggregation}`]
            ? 'up'
            : 'down'
          }
          secondaryValue={current_round.score - summary.score[`${timeframe}_${aggregation}`]}
          secondaryUnits=''
        />
      </WrapItem>
      <WrapItem>
        <BasicMetric 
          value={current_round.score_to_par}
          label='To Par'
          secondaryArrow={
            current_round.score_to_par <= summary.score_to_par[`${timeframe}_${aggregation}`]
            ? 'up'
            : 'down'
          }
          secondaryValue={current_round.score_to_par - summary.score_to_par[`${timeframe}_${aggregation}`]}
          secondaryUnits=''
        />
      </WrapItem>
      <WrapItem>
        <BasicMetric 
          value={round(current_round.differential)}
          label='Differential'
          secondaryArrow={
            current_round.differential <= summary.differential[`${timeframe}_${aggregation}`]
            ? 'up'
            : 'down'
          }
          secondaryValue={round(current_round.differential - summary.differential[`${timeframe}_${aggregation}`])}
          secondaryUnits=''
        />
      </WrapItem>
      <WrapItem>
        <BasicMetric 
          value={round(current_round.strokes_gained.overall)}
          label='Strokes Gained'
          secondaryArrow={
            current_round.strokes_gained.overall >= summary.strokes_gained[`overall_${timeframe}_${aggregation}`]
            ? 'up'
            : 'down'
          }
          secondaryValue={round(summary.strokes_gained[`overall_${timeframe}_${aggregation}`] - current_round.strokes_gained.overall)}
          secondaryUnits=''
        />
      </WrapItem>
      <WrapItem>
        <BasicMetric 
          value={round(current_round.num_quality_shots)}
          label='Quality Shots'
          secondaryArrow={
            current_round.num_quality_shots >= summary.quality_shots[`${timeframe}_${aggregation}`]
            ? 'up'
            : 'down'
          }
          secondaryValue={round(current_round.num_quality_shots - summary.quality_shots[`${timeframe}_${aggregation}`])}
          secondaryUnits=''
        />
      </WrapItem>
      <WrapItem>
        <BasicMetric 
          value={round(current_round.num_flubbed_shots)}
          label='Flubs'
          secondaryArrow={
            current_round.num_flubbed_shots < summary.flubbed_shots[`${timeframe}_${aggregation}`]
            ? 'up'
            : 'down'
          }
          secondaryValue={Math.abs(round(summary.flubbed_shots[`${timeframe}_${aggregation}`] - current_round.num_flubbed_shots))}
          secondaryUnits=''
        />
      </WrapItem>
    </Wrap>
  }

  const round = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  // check to ensure necessary data is loaded
  const current_round = useSelector( state => state.rounds.current_round)
  const courses = useSelector( state => state.courses.courses)

  if (!current_round) return <PageLoader />
  if (courses.length < 1) return <PageLoader />
  if (Object.keys(summary).length < 1) return <PageLoader />

  const per_category_data = Object.keys(current_round.strokes_gained)
    .filter( s => s !== 'overall')
    .map( s => ({category: s, value: current_round.strokes_gained[s], seriesName: 'Strokes Gained'}))

  const holes = current_round.course.course_holes.map( h => h.id)
  const hole_shots = holes
    .map( (h) => current_round.round_shots
      .filter( s => s.hole === h)
      .sort( (a, b) => a.shot_number - b.shot_number)
      .map( s => s.strokes_gained)  
    )

  return <PageContainer>
    <HStack w='100%' marginBottom={'2rem'} ref={column_ref}>
      {renderHeader()}
    </HStack>
    <VStack 
      alignItems='center'
      align='center'
      justify={'center'}
      w='100%'
    > 
      <Wrap w='100%' justify={{base: 'center', md: 'space-between'}} align={'center'} spacing={4 }>
        <WrapItem>
          {renderPrimaryMetrics()}
        </WrapItem>
        <WrapItem>
          <StatRadar 
            data={per_category_data}
            seriesName='Strokes Gained'
          />
        </WrapItem>
      </Wrap>
    </VStack>
    <VStack 
      alignItems='center'
      justifyContent={'space-between'}
      w='100%'
      spacing={10}
    > 
      <HStack w='100%'><Heading size='lg'>Hole Performance</Heading></HStack>
      <StackedSignChart 
        data={hole_shots}
        series={['0', '1', '2', '3', '4', '5', '6', '7']}
        width={col_width}
        seriesPrefix='shot'
      />
    </VStack>
  </PageContainer>
}