import { extendTheme } from "@chakra-ui/react";

export const colors = {
  gray: {
    10: '#FFFFEF',
    50: '#ECEBE7',
    100: '#F7F7F5',
    200: '#F4F3F1',
    300: '#F0EFEC',
    400: '#ECEBE7',
    500: '#BDBCB9',
    600: '#8E8D8B',
    700: '#5E5E5C',
    800: '#2F2F2E',
    900: '#181717',
  },
  primary: {
    50: '#F2F8F2',
    100: '#CCE2CB',
    200: '#B2D4B2',
    300: '#99C598',
    400: '#7FB77E',
    500: '#669265',
    600: '#4C6E4C',
    700: '#334932',
    800: '#192519',
    900: '#0D120D',
  },
  secondary: {
    50: '#EFF7F0',
    100: '#E0EFE1',
    200: '#D0E7D2',
    300: '#C1DFC3',
    400: '#B1D7B4',
    500: '#8EAC90',
    600: '#6A816C',
    700: '#475648',
    800: '#232B24',
    900: '#121512',
  },
  accent: {
    50: '#FFF2E9',
    100: '#FFE6D3',
    200: '#FFD9BC',
    300: '#FFCDA6',
    400: '#FFC090',
    500: '#CC9A73',
    600: '#B38665',
    700: '#806048',
    800: '#4C3A2B',
    900: '#19130E',
  },
  highlight: {
    50: '#FDFDFF',
    100: '#FCFBF1',
    200: '#FCFBF1',
    300: '#F9F8E3',
    400: '#F7F6DC',
    500: '#C6C5B0',
    600: '#949484',
    700: '#636258',
    800: '#31312C',
    900: '#191916',
  },
  gradient: {
    1: '#ffc090',
    2: '#f3bf88',
    3: '#e7bf81',
    4: '#d9bf7c',
    5: '#cbbe78',
    6: '#bdbd76',
    7: '#aebc76',
    8: '#9fbb77',
    9: '#8fb97a',
    10: '#7fb77e'
  }
}

export const theme = extendTheme({
  colors,
  styles: {
    global: {
      body: {
        bg: 'highlight.50'
      }
    }
  },
  components: {
   
  },
});

