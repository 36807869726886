import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { login, logout, requestReset, getMe, createUser, activateUser, confirmReset } from "./api";

const initialState = {
  isLoggedIn: false,
  token: '',
  status: 'idle',
  error_message: '',
  user: {},
  sign_up: {
    status: 'idle',
    error_message: '',
    success: false,
    activated: false,
    reset_requested: false,
    reset_complete: false,
  }
}

export const activateNewUser = createAsyncThunk(
  'auth/activateNewUser',
  async (payload) => {
    return activateUser(payload)
  }
)

export const createNewUser = createAsyncThunk(
  'auth/createNewUser',
  async (payload) => {
    return createUser(payload)
  }
)

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (payload) => {
    return getMe(payload.token)
  }
)

export const getLogin = createAsyncThunk(
  'auth/getLogin',
  async (payload) => {
    
    const response = await login(payload.email, payload.password)
    const user =  await getMe(response.auth_token)
    return {
      auth_token: response.auth_token,
      user
    }  
  }
)

export const getLogout = createAsyncThunk(
  'auth/getLogout',
  async (payload) => {
    return logout(payload.token)
  }
)

export const getReset = createAsyncThunk(
  'auth/getReset',
  async (email) => {
    return requestReset(email)
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload) => {
    return confirmReset(payload)
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error_message = ''
    },
    reset: () => initialState,
    resetSignUp: (state) => {
      state.sign_up = initialState.sign_up
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getLogin.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getLogin.fulfilled, (state, action) => {
        state.status = 'idle'
        state.token = action.payload.auth_token
        state.user = action.payload.user
        state.error_message = ''
        state.isLoggedIn = true
        localStorage.setItem('TOKEN', action.payload.auth_token)
      })
      .addCase(getLogin.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to login with the provided credentials'
      })
      .addCase(getLogout.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getLogout.fulfilled, (state) => {
        state.status = 'idle'
        state.token = ''
        state.isLoggedIn = false
        state.user = {}
        localStorage.setItem('TOKEN', null)
      })
      .addCase(getLogout.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to expire token'
        state.token = ''
        state.isLoggedIn = false
        state.user = {}
      })
      .addCase(getReset.pending, (state) => {
        state.sign_up.status = 'loading'
        state.isLoggedIn = false
        state.sign_up.error_message = ''
        state.user = {}
      })
      .addCase(getReset.fulfilled, (state) => {
        state.sign_up.status = 'idle'
        state.sign_up.reset_requested = true
        state.sign_up.error_message = ''
      })
      .addCase(getReset.rejected, (state, action) => {
        state.sign_up.status = 'idle'
        state.sign_up.reset_requested = false
        state.sign_up.error_message = action.error.message
      })
      .addCase(resetPassword.pending, (state) => {
        state.sign_up.status = 'loading'
        state.isLoggedIn = false
        state.sign_up.error_message = ''
        state.sign_up.reset_complete = false
        state.user = {}
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.sign_up.status = 'idle'
        state.sign_up.reset_complete = true
        state.sign_up.error_message = ''
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.sign_up.status = 'idle'
        state.sign_up.reset_complete = false
        state.sign_up.error_message = action.error.message
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.status = 'idle'
        state.user = action.payload
      })
      .addCase(createNewUser.pending, (state) => {
        state.sign_up.status = 'loading'
        state.error_message = ''
        state.sign_up.reset_requested = false
      })
      .addCase(createNewUser.fulfilled, (state) => {
        state.sign_up.status = 'idle'
        state.error_message = ''
        state.sign_up.success = true
        state.sign_up.reset_requested = false
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.sign_up.status = 'idle'
        state.sign_up.error_message = action.error.message
        state.sign_up.reset_requested = false
      })
      .addCase(activateNewUser.pending, (state) => {
        state.sign_up.status = 'loading'
        state.error_message = ''
        state.sign_up.reset_requested = false
      })
      .addCase(activateNewUser.fulfilled, (state) => {
        state.sign_up.status = 'idle'
        state.error_message = ''
        state.sign_up.activated = true
        state.sign_up.reset_requested = false
      })
      .addCase(activateNewUser.rejected, (state, action) => {
        state.sign_up.status = 'idle'
        state.sign_up.error_message = action.error.message
      })
  }
})

export const { clearErrors } = authSlice.actions

export default authSlice.reducer