import React from 'react';

import { ChakraProvider, Box } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoutes } from './utils/PrivateRoutes'

import { theme } from './theme'

import { Activate } from './routes/Activate';
import { AddRound } from './routes/AddRound';
import { BlogHome } from './routes/BlogHome';
import { Dashboard } from './routes/Dashboard';
import { EditRound } from './routes/EditRound';
import { Home } from './routes/Home';
import { Login } from './routes/Login';
import { Reset } from './routes/Reset';
import { ResetPasswordConfirm } from './routes/ResetPasswordConfirm';
import { Rounds } from './routes/Rounds';
import { ShotAnalyzer } from './routes/ShotAnalyzer';
import { Signup } from './routes/Signup';
import { ViewRound } from './routes/ViewRound';

import mixpanel from 'mixpanel-browser';
import SidebarWithHeader from './components/Sidebar';
import { BlogPage } from './routes/BlogPage';


function App() {

  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {debug: false}); 

  return (
    <ChakraProvider theme={theme}>
      <Box className="App">
        <BrowserRouter>
          <SidebarWithHeader>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path='/' element={<Home />}/>
                <Route path='/dashboard' element={<Dashboard />}/>
                <Route path='/rounds' element={<Rounds />}/>
                <Route path='/round/create' element={<AddRound />}/>
                <Route path='/round/:round_id/edit' element={<EditRound />}/>
                <Route path='/round/:round_id/view' element={<ViewRound />}/>
                <Route path='/shots/' element={<ShotAnalyzer />}/>
              </Route>
              <Route path='/reset_password' element={<Reset />}/>
              <Route path='/reset_password/confirm/:uid/:token' element={<ResetPasswordConfirm />}/>
              <Route path='/login' element={<Login />}/>
              <Route path='/signup' element={<Signup />}/>
              <Route path='/activate/:uid/:token' element={<Activate />}/>
              <Route path='/b/' element={<BlogHome />}/>
              <Route path='/b/:slug/' element={<BlogPage />}/>
            </Routes>
          </SidebarWithHeader>
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  );
}

export default App;
