import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Heading, VStack } from "@chakra-ui/react";
import { RoundList } from "../components/RoundList/RoundList";

import { StackedSignChart } from "../components/StackedSignChart";

import { getRounds } from "../features/rounds";
import { roundsSlice } from "../features/rounds";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageLoader } from "../components/PageLoader";
import { PageContainer } from "../components/PageContainer";

export function Home() {

  const page_title = 'Home'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const token = useSelector( state => state.auth.token )
  const rounds = useSelector( state => state.rounds.rounds )

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
    
    dispatch(getRounds({ token }))
    dispatch(roundsSlice.actions.clearCurrentRound())
  }, [])

  // Dynamically allocate column widths as user resized window
  const column_ref = useRef(0);
  const [col_width, setColWidth] = useState(0);
  useEffect(() => {
    setColWidth(column_ref.current.offsetWidth);
    const handleResize = () => {
      setColWidth(column_ref.current.offsetWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  const per_round_data = rounds
    .map( r => ({...r.strokes_gained, name: r.date, click_url: `/round/${r.id}/view`}))

    if (Object.keys(rounds).length < 1) return <PageLoader />

  return <PageContainer>
    <VStack alignItems={'flex-start'} spacing={4} ref={column_ref} w='100%'>
      <Heading>Recent Performance</Heading>
      <StackedSignChart 
        data={per_round_data.slice(-10)}
        width={col_width*.9}
      />
    </VStack>

    <VStack alignItems={'flex-start'} spacing={4} w='100%'>
      <Heading>All Rounds</Heading>
      <RoundList rounds={rounds}/>
    </VStack>
  </PageContainer>
}