import { VStack } from '@chakra-ui/react';

export function PageContainer(props) {

  return <VStack 
    minH={'100vh'}
      align={'flex-start'}
      justify={'center'}
      p={{base: 0, md: 10}}
      w={'100%'}
      {...props}
  >
    {props.children}
  </VStack>
}