import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/stat/`,
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
  }
});

const routes = {
  summary: 'summary/',
}

export const getUserSummary = async (payload) => {
  const { data } = await instance.get(
    `${routes.summary}`,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      }
    }
  );
  return data;
}