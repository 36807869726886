import axios from 'axios'

const token = localStorage.getItem('TOKEN')

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Token ${token}`
  }
});

const routes = {
  shots: 'shot/',
  shot_filters: 'shot_filter/',
  shot_types: 'shot_type/',
}

export const getShots = async (page, filters) => {
  const { data } = await instance.get(
    routes.shots,
    {
      params: {
        ...filters,
        page: page
      }
    }
  );
  return data;
}

export const getShotFilters = async () => {
  const { data } = await instance.get(
    routes.shot_filters
  );
  return data;
}

export const createShotFilter = async (payload) => {
  const { data } = await instance.post(
    routes.shot_filters,
    payload
  );
  return data;
}

export const getShotTypes = async () => {
  const { data } = await instance.get(
    routes.shot_types
  );
  return data;
}