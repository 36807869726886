import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  TableContainer, Table, Tbody, Thead, Tr, Th, Td,
  Menu, MenuButton, IconButton, MenuList, MenuItem, SkeletonText,
  useBreakpointValue, VStack, HStack, Heading, Box, Text
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, HamburgerIcon, ViewIcon } from '@chakra-ui/icons';
import { deleteRound, getRounds } from '../../features/rounds';
import { PageLoader } from '../PageLoader';

export function RoundList(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector( state => state.auth.token );
  const rounds_loading = useSelector( state => state.rounds.status)

  const handleDeleteRound = (round_id) => {
    dispatch(deleteRound({token, round_id})).then(() => dispatch(getRounds({token})))
  }

  const renderListRows = () => {
    
    if (!props.rounds) return null

    return props.rounds.map( (r,i) => {
      return <Box 
        borderBottom={'1px'} 
        borderBottomColor={'gray.400'} 
        w='100%' 
        paddingTop={2}
        paddingBottom={2} 
        _hover={{
          background: 'gray.100'
        }}
        onClick={() => navigate(`/round/${r.id}/view`)}  
      >
        <HStack justifyContent='space-between'>
          <VStack align={'flex-start'}>
            <Heading size={'xs'}>{r.course.name}</Heading>
            <Text as='cite' fontSize={'xs'}>{`${r.tee.name} | ${r.date}`}</Text>
          </VStack>
          <HStack>
            <IconButton 
              icon={<ViewIcon />} 
              variant='ghost'
              onClick={() => navigate(`/round/${r.id}/view`)}  
            />
          </HStack>
        </HStack>
      </Box>
    })
  }

  const renderTableRows = () => {
    
    if (!props.rounds) return null

    return <>
      {props.rounds.map( (round, i) => {
        return <Tr key={i}>
          <Td>{round.date}</Td>
          <Td>{round.course.name}</Td>
          <Td>{round.tee.name}</Td>
          <Td>{round.score}</Td>
          <Td>{Math.round((round.differential + Number.EPSILON) * 100) / 100}</Td>
          <Td>{Math.round((round.strokes_gained.overall + Number.EPSILON) * 100) / 100}</Td>
          <Td>
            <IconButton 
              icon={<ViewIcon />} 
              variant='ghost'
              onClick={() => navigate(`/round/${round.id}/view`)}  
            />
            <Menu>
              <MenuButton 
                as={IconButton}
                aria-label={'Options'}
                icon={<HamburgerIcon />}
                variant={'ghost'}
              />
              <MenuList>
                <MenuItem icon={<EditIcon />} onClick={() => navigate(`/round/${round.id}/edit`)}>
                  Edit Round
                </MenuItem>
                <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteRound(round.id)}>
                  Delete Round
                </MenuItem>
              </MenuList>
            </Menu>
          </Td>
        </Tr>
      })}
    </>
  }

  const renderSkeletonRows = () => {
    return [0,1,2,3,4].map( r =>
      <Tr key={r}>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
      </Tr>
    )
  }

  const display_format = useBreakpointValue(
    {base: 'list', md: 'table'}
  )

  if (display_format === 'table') return (
      <TableContainer w='100%'>
        <Table variant='striped' w='100%' size={{base: 's', md: 'lg'}}>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Course</Th>
              <Th>Tees</Th>
              <Th>Score</Th>
              <Th>Differential</Th>
              <Th>Strokes Gained</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {rounds_loading === 'idle'
              ? renderTableRows()
              : renderSkeletonRows()
            }
          </Tbody>
        </Table>
      </TableContainer>
    );
  else return (
    <VStack w={'100%'} spacing={0}>
      {rounds_loading === 'idle' ? renderListRows() : <PageLoader />}
    </VStack>
  )
}

RoundList.propTypes = {
  rounds: PropTypes.array
}

RoundList.defaultProps = {
  rounds: []
}