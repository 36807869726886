import PropTypes from 'prop-types'
import {
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  ReferenceLine,
  ComposedChart,
  Cell
} from 'recharts';
import { colors } from '../../theme';

export function VerticalBreakdownChart(props) {

  const getFill = (entry) => {
    if (entry[props.dataKey] >= 0) return colors.secondary[400]
    return colors.accent[400]
  }

  const formatTooltipValue = (value, name, props) => {
    return value.toFixed(3)
  }

  return <ComposedChart
    data={props.data}
    layout={'vertical'}
    width={props.width}
    height={props.height}
  >
    <YAxis stroke={colors.gray[600]} dataKey='name' type='category' axisLine={false} tickLine={false} tick={{fontSize: '0.8rem'}}/>
    <XAxis type='number' tickLine={false} tick={{fontSize: '0.8rem'}}/>
    <ReferenceLine x={0} />
    <Tooltip
      contentStyle={{
        outline: 'none',
        border: 'none',
        borderRadius: '.25rem',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
      }}
      wrapperStyle={{
        border: 'none',
        outline: 'none',
      }}
      formatter={formatTooltipValue}
    />
    <Bar 
      dataKey={props.dataKey}
    >
      {props.data.map((entry, index) => {
        return <Cell key={index} fill={getFill(entry)} />
      })}
    </Bar>
  </ComposedChart>
}

VerticalBreakdownChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataKey: PropTypes.string.isRequired
}

VerticalBreakdownChart.defaultProps = {
  width: 300,
  height: 300
}