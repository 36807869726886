import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Flex, Box, FormControl, FormLabel, Input, Stack,
  Button, Heading, Text, Link, Alert, AlertDescription, AlertIcon,
} from '@chakra-ui/react';

import { useNavigate } from "react-router-dom";

import { getLogin, authSlice } from '../../features/auth'
import mixpanel from 'mixpanel-browser';
import { COMPLETED_SIGN_IN, STARTED_SIGN_IN, SUBMITTED_SIGN_IN, } from '../../analytics/events';

export function SignInCard() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [firedStart, toggleFiredStart] = useState(false)

  useEffect(() => {
    document.title = 'Login'
    dispatch(authSlice.actions.resetSignUp())
  }, [])

  const error_message = useSelector(state => state.auth.error_message)

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleEmailChange = (value) => {

    if (!firedStart) {
      mixpanel.track(
        STARTED_SIGN_IN.name
      )
      toggleFiredStart(true)
    }

    setFormData({...formData, email: value})

  }

  const handlePasswordChange = (value) => {

    if (!firedStart) {
      mixpanel.track(
        STARTED_SIGN_IN.name
      )
      toggleFiredStart(true)
    }

    setFormData({...formData, password: value})

  }

  const { email, password } = formData;

  const handleFormSubmit = async (e)  => {
    e.preventDefault();

    mixpanel.track(SUBMITTED_SIGN_IN.name)

    dispatch(getLogin({email, password})).then(() => {
      mixpanel.track(COMPLETED_SIGN_IN.name)
      navigate('/')
    })

  } 

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          boxShadow={'lg'}
          p={8}
          minW={'400px'}>
          <Stack spacing={4}>
            {error_message !== ''
              ? <Alert status='error'>
                <AlertIcon />
                <AlertDescription>{error_message}</AlertDescription>
              </Alert>
              : null
            }
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={e => handleEmailChange(e.target.value)}/>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={e => handlePasswordChange(e.target.value)}/>
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={'primary.400'}
                color={'white'}
                _hover={{
                  bg: 'primary.300',
                }}
                onClick={handleFormSubmit}
              >
                Sign in
              </Button>
            </Stack>
            <Box w='100%' justifyContent={'center'} display='flex'>
              <Link onClick={() => navigate('/reset_password')}>Reset Password</Link>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}