import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { Flex, Text, Box, Stack, Spinner, Button,
         Alert, AlertIcon, AlertDescription, FormControl,
         FormLabel, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSlice, resetPassword } from "../features/auth";
import { CheckCircleIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from '../components/PageContainer';

export function ResetPasswordConfirm() {
  
  const page_title = 'Confirm Password Reset'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('')
  const [re_password, setRePassword] = useState('')

  const sign_up = useSelector(state => state.auth.sign_up)
  const { uid, token } = useParams()
  
  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
    
    dispatch(authSlice.actions.resetSignUp())
  }, [])

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword({
      uid, token, password, re_password
    }))
  }

  return <PageContainer>
    <Box
      rounded={'lg'}
      boxShadow={'lg'}
      p={8}
      minW={'400px'}
      marginTop={'50px'}
    >
      <Stack spacing={4} alignItems='center'>
        {sign_up.error_message !== ''
          ? <Alert status='error'>
            <AlertIcon />
            <AlertDescription>{sign_up.error_message}</AlertDescription>
          </Alert>
          : null
        }
        {sign_up.reset_complete
          ? <>
              <CheckCircleIcon color={'primary.400'}  boxSize='3em'/>
              <Text fontSize={'3xl'} textAlign='center'>You're password is reset!</Text>
              <Button
                size="lg"
                bg={'primary.400'}
                color={'white'}
                onClick={() => navigate('/login')}
                _hover={{
                  bg: 'primary.300',
                }}>
                Login
              </Button>
            </>
          : <><FormControl id="password" isRequired>
            <FormLabel>New Password</FormLabel>
            <InputGroup>
              <Input 
                type={showPassword ? 'text' : 'password'} 
                value={password}  
                onChange={e => setPassword(e.target.value)}
              />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={() =>
                    setShowPassword((showPassword) => !showPassword)
                  }>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="re_password" isRequired>
            <FormLabel>Re-type Password</FormLabel>
            <InputGroup>
              <Input 
                type={showPassword ? 'text' : 'password'} 
                value={re_password}
                onChange={e => setRePassword(e.target.value)}
              />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={() =>
                    setShowPassword((showPassword) => !showPassword)
                  }>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Stack spacing={10}>
            <Button
              loadingText='Resetting Password'
              isLoading={sign_up.status === 'loading'}
              bg={'primary.400'}
              color={'white'}
              _hover={{
                bg: 'primary.300',
              }}
              onClick={handleFormSubmit}
            >
              Reset Password
            </Button>
          </Stack></>
        }
        
      </Stack>
    </Box>
  </PageContainer>
}