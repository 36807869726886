import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from 'prop-types'
import { AsyncSelect } from 'chakra-react-select';
import { colors } from '../../theme';
import { searchCourses, setCurrentCourse } from "../../features/courses";

export function CourseSelect(props) {
  
  const dispatch = useDispatch();

  const token = useSelector( state => state.auth.token );

  const courses = useSelector( state => state.courses.courses);
  
  const handleChange = (c) => {
    if (!c) return
    const course = courses.find( f => f.id === c.value)
    dispatch(setCurrentCourse(course))
  }

  const loadOptions = (inputValue, callback) => {

    dispatch(searchCourses({ token, query: inputValue }))
      .then( () => {
        const filteredOptions = courses.map( c => ({ value: c.id, label: c.name }))
        callback(filteredOptions)
      })
  }

  return <AsyncSelect
    loadOptions={loadOptions}
    placeholder={'Enter a course name'}
    defaultOptions={courses.map(c => ({ value: c.id, label: c.name }))}
    onChange={handleChange}
    isSearchable
    isClearable
    isMulti={props.isMulti}
  />
}

CourseSelect.propTypes = {
  isMulti: PropTypes.bool
}


CourseSelect.defaultProps = {
  isMulti: false
}