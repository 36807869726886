import { useState } from 'react';

import PropTypes from 'prop-types'

import { Select } from "chakra-react-select";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { Box, FormControl } from '@chakra-ui/react';

import { CourseSelect } from '../CourseSelect';

export function RoundForm(props) {

  const [course, setCourse] = useState(props.course)
  const [tee, setTee] = useState(props.tee)
  const [date, setDate] = useState(props.date)

  const getTees = () => {

    if (!course || !course.id) return []

    return props.courses.find(c => c.id === course.id)
      .course_tees
      .map( t => ({ value: t.id, label: t.name }))

  }

  return (
    <>
        <Box w='50%' marginRight={'10px'}>
          <FormControl>
            <CourseSelect />
          </FormControl>
        </Box>
        <Box w='25%' marginRight={'10px'}>
          <Select
            isDisabled={!course}
            value={tee}
            onChange={setTee}
            options={getTees()}
            useBasicStyles
          />
        </Box>
        <Box w='25%'>
          <SingleDatepicker
            date={Date.parse(date)} 
            onDateChange={setDate}
          />
        </Box>
    </>
  );
}

RoundForm.propTypes = {
  courses: PropTypes.arrayOf(Object),
  course: PropTypes.object,
  date: PropTypes.string,
  tee: PropTypes.number,
}

RoundForm.defaultProps = {
  courses: [],
  course: {}
}