import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
  }
});

const routes = {
  courses: 'course/',
  course_feedback: 'course_feedback/',
  hole_lengths: 'hole/',

}

export const getAllCourses = async (token) => {
  const { data } = await instance.get(
    routes.courses,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${token}`
      }
    }
  );
  return data;
}

export const getCourseHoles = async (payload) => {
  const { data } = await instance.get(
    routes.hole_lengths,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      },
      params: {
        course: payload.course,
        tee: payload.tee
      }
    }
  );
  return data;
}

export const searchAllCourses = async (payload) => {
  const { data } = await instance.get(
    routes.courses,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      },
      params: {
        q: payload.query
      }
    }
  )
  return data
}

export const postCourseFeedback = async (payload) => {
  const { data } = await instance.post(
    routes.course_feedback,
    {
      course: payload.course_id,
      message: payload.message
    },
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${payload.token}`
      }
    }
  )
}