import React from 'react';
import { useTable } from 'react-table';

import PropTypes from 'prop-types'

import { Table, Thead, Tr, Td, Th, Tbody, HStack } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

export function DataTable(props) {
  const {columns, data} = props;
  const tableInstance = useTable({columns, data});
  
  return (
    <>
    <Table {...tableInstance.getTableProps()}>
      <Thead>
        {tableInstance.headerGroups.map(headerGroup => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...tableInstance.getTableBodyProps()}>
        {tableInstance.rows.map((row, i) => {
          tableInstance.prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
    <HStack>
      <IconButton 
        aria-label="Previous Page" 
        icon={<FiChevronLeft />} 
        disabled={props.currentPage === 1}
        onClick={props.getPrev}
      />
      <p>Page {props.currentPage} of {props.totalPages ? props.totalPages : 1}</p>
      <IconButton 
        aria-label="Next Page" 
        icon={<FiChevronRight />} 
        disabled={props.currentPage === props.totalPages}
        onClick={props.getNext}
      />
    </HStack>
    </>
  )
}

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  getNext: PropTypes.func,
  getPrev: PropTypes.func,
}

DataTable.defaultProps = {
  columns: [],
  data: [],
  currentPage: 1,
  totalPages: 1,
  loading: true,
  getNext: null,
  getPrev: null,
}