import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getAllPages, getAPage } from './api'

const initialState = {
  status: 'idle',
  error_message: '',
  all_pages: [],
  current_page: null,
}

export const getPages = createAsyncThunk(
  'blog/getPages',
  async () => {
    return getAllPages()
  }
)

export const getPage = createAsyncThunk(
  'blog/getPage',
  async (payload) => {
    return getAPage(payload)
  }
)

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error_message = ''
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPages.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.status = 'idle'
        state.all_pages = action.payload
      })
      .addCase(getPages.rejected, (state, action) => {
        state.status = 'idle'
        state.error_message = action.error.message
      })
      .addCase(getPage.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.status = 'idle'
        state.current_page = action.payload
      })
      .addCase(getPage.rejected, (state, action) => {
        state.status = 'idle'
        state.error_message = action.error.message
      })
  }
})

export const { clearErrors } = blogSlice.actions

export default blogSlice.reducer