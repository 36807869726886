// PROPERTIES  ///////////////////////////////

export const PAGE_TITLE = 'page_title'


//  EVENTS  /////////////////////////////////

////  GENERAL ///////////////////////////////
export const VIEWED_PAGE = {
  name: 'viewed_page',
  properties: [
    PAGE_TITLE
  ]
}

////  AUTH  //////////////////////////////////
export const STARTED_SIGN_IN = {
  name: 'started_sign_in',
  properties: [],
}

export const SUBMITTED_SIGN_IN = {
  name: 'submitted_sign_in',
  properties: [],
}

export const COMPLETED_SIGN_IN = {
  name: 'completed_sign_in',
  properties: [],
}

export const STARTED_SIGN_UP = {
  name: 'started_sign_up',
  properties: [],
}

export const SUBMITTED_SIGN_UP = {
  name: 'submitted_sign_up',
  properties: [],
}

export const COMPLETED_SIGN_UP = {
  name: 'completed_sign_up',
  properties: [],
}

////  ROUND MANAGEMENT //////////////////////
export const ADDED_ROUND = {
  name: 'added_round',
  properties: [],
}

export const DELETED_ROUND = {
  name: 'deleted_round',
  properties: [],
}

export const EDITED_SHOTS = {
  name: 'edited_shots',
  properties: [],
}