import { Box, Stack, Heading, Text } from "@chakra-ui/react"

import { PageContainer } from "../components/PageContainer"

export function BlogHome() {
  return <PageContainer>
    <Stack as={Box} textAlign={'center'} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
      <Heading>How to Track Strokes Gained</Heading>
    </Stack>
  </PageContainer>
}