import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllCourses, getCourseHoles, searchAllCourses, postCourseFeedback } from "./api";

const initialState = {
  status: 'idle',
  error_message: '',
  next_page: null,
  previous_page: null,
  courses: [],
  current_course: null,
  current_course_holes: [],
  course_feedback_status: 'idle'
}

export const getCourses = createAsyncThunk(
  'course/getCourses',
  async (payload) => {
    return getAllCourses(payload.token)
  }
)

export const getHoles = createAsyncThunk(
  'course/getHoles',
  async (payload) => {
    return getCourseHoles(payload)
  }
)

export const searchCourses = createAsyncThunk(
  'course/searchCourses',
  async (payload) => {
    return searchAllCourses(payload)
  }
)

export const sendCourseFeedback = createAsyncThunk(
  'course/sendCourseFeedback',
  async (payload) => {
    return postCourseFeedback(payload)
  }
)

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error_message = ''
    },
    setCurrentCourse: (state, action) => {
      state.current_course = action.payload
    },
    clearFeedbackStatus: (state) => {
      state.course_feedback_status = 'idle'
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.status = 'idle'
        state.courses = action.payload.results
        state.next_page = action.payload.next
        state.previous_page = action.payload.previous
      })
      .addCase(getCourses.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to fetch courses, try again later'
      })
      .addCase(getHoles.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getHoles.fulfilled, (state, action) => {
        state.status = 'idle'
        state.error_message = ''
        state.current_course_holes = action.payload.results
      })
      .addCase(getHoles.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to fetch holes, try again later'
      })
      .addCase(searchCourses.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(searchCourses.fulfilled, (state, action) => {
        state.status = 'idle'
        state.courses = action.payload.results
        state.next_page = action.payload.next
        state.previous_page = action.payload.previous
      })
      .addCase(searchCourses.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to fetch courses, try again later'
      })
      .addCase(sendCourseFeedback.pending, (state) => {
        state.course_feedback_status = 'loading'
        state.error_message = ''
      })
      .addCase(sendCourseFeedback.fulfilled, (state) => {
        state.course_feedback_status = 'complete'
      })
      .addCase(sendCourseFeedback.rejected, (state) => {
        state.course_feedback_status = 'failed'
        state.error_message = 'unable to save feedback, try again later!'
      })

  }
})

export const { clearErrors, setCurrentCourse, clearFeedbackStatus } = coursesSlice.actions

export default coursesSlice.reducer