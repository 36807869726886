import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { VStack, Heading, HStack, Select, Wrap, WrapItem, useBreakpointValue } from "@chakra-ui/react";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";

import { getRounds } from "../features/rounds";
import { TrendMetric } from "../components/TrendMetric";
import { getSummary } from "../features/stats";
import { PageLoader } from "../components/PageLoader";
import { StackedSignChart } from "../components/StackedSignChart";
import { StatRadar } from "../components/StatRadar";
import { VerticalBreakdownChart } from "../components/VerticalBreakdownChart";
import { PageContainer } from "../components/PageContainer";

export function Dashboard() {
  
  const page_title = 'Dashboard'

  const dispatch = useDispatch();

  // Dynamically allocate column widths as user resized window
  const column_ref = useRef(0);
  const [col_width, setColWidth] = useState(0);
  useEffect(() => {
    setColWidth(column_ref.current ? column_ref.current.offsetWidth : 0);
    const handleResize = () => {
      setColWidth(column_ref.current.offsetWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })
  const radar_width = useBreakpointValue(
    { base: col_width, md: col_width*.4 },
    {fallback: col_width*.4}
  )
  const bar_width = useBreakpointValue(
    { base: col_width, md: col_width*.6 },
    { fallback: col_width*.6 }
  )
  const vert_bar_width = useBreakpointValue(
    { base: col_width, md: col_width*.49 },
    { fallback: col_width*.6 }
  )

  const token = useSelector( state => state.auth.token );
  const summary = useSelector( state => state.stats.summary)
  const { round_id } = useParams()

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])

  useEffect(() => {
    dispatch(getRounds({ token }))
    dispatch(getSummary({ token }))
  }, [dispatch, token, round_id])

  const [timeframe, setTimeframe] = useState('alltime')
  const [aggregation, setAggregation] = useState('avg')
  
  const rounds = useSelector( state => state.rounds.rounds )

  if (Object.keys(rounds).length < 1) return <PageLoader />
  if (Object.keys(summary).length < 1) return <PageLoader />

  const per_round_data = rounds
    .map( r => ({...r.strokes_gained, name: r.date, click_url: `/round/${r.id}/view`}))
  const per_category_data = [
    { category: 'approach', value: summary.strokes_gained[`approach_${timeframe}_${aggregation}`], seriesName: 'Strokes Gained'},
    { category: 'around_the_green', value: summary.strokes_gained[`around_the_green_${timeframe}_${aggregation}`], seriesName: 'Strokes Gained'},
    { category: 'driving', value: summary.strokes_gained[`driving_${timeframe}_${aggregation}`], seriesName: 'Strokes Gained'},
    { category: 'greenside', value: summary.strokes_gained[`greenside_${timeframe}_${aggregation}`], seriesName: 'Strokes Gained'},
    { category: 'putting', value: summary.strokes_gained[`putting_${timeframe}_${aggregation}`], seriesName: 'Strokes Gained'},
  ]

  const approach_labels = {
    short: '<120 yds',
    mid: '120-170 yds',
    long: '170-220 yds',
    xlong: '220+ yds',
  }
  const approach_data = Object.keys(summary.approach)
    .filter( a => a.includes(timeframe))
    .filter( a => a.includes(aggregation))
    .map( a => ({ name: approach_labels[a.split('_')[0]], sg: summary.approach[a]}))

  const putting_data = Object.keys(summary.putting)
    .filter( a => a.includes(timeframe))
    .filter( a => a.includes(aggregation))
    .map( a => ({ name: `${a.split('_')[0]} ft`, sg: summary.putting[a]}))

  const rounds_to_show_map = {
    last4: 4,
    last10: 10,
    alltime: 10,
  }

  return <PageContainer>
    <Wrap 
      ref={column_ref} 
      w='100%' 
      justify={{ sm: 'center', md: 'space-between'}}
    >
      <WrapItem>
          <Heading>Performance History</Heading>
      </WrapItem>
      <WrapItem>
        <HStack>
          <Select value={timeframe} onChange={e => setTimeframe(e.target.value)}>
            <option value='alltime'>All Time</option>
            <option value='last4'>Last 4 Rounds</option>
            <option value='last10'>Last 10 Rounds</option>
          </Select>
          <Select value={aggregation} onChange={e => setAggregation(e.target.value)}>
            <option value='avg'>Average</option>
            <option value='med'>Median</option>
            <option value='best'>Best</option>
            <option value='worst'>Worst</option>
          </Select>
        </HStack>
      </WrapItem>
    </Wrap>
    <VStack w='100%' spacing={5} paddingTop={5} align={'flex-start'}>
      <HStack w='100%'><Heading size='lg'>Overall</Heading></HStack>
      <Wrap justify={'center'}>
        <WrapItem>
          <TrendMetric 
            value={summary.strokes_gained[`overall_${timeframe}_${aggregation}`]}
            label='Strokes Gained'
            trendValues={rounds.map( r => ({m: r.strokes_gained.overall}) )}
          />
        </WrapItem>
        <WrapItem>
          <TrendMetric 
            value={summary.quality_shots[`${timeframe}_${aggregation}`]}
            label='Quality Shots'
            trendValues={rounds.map( r => ({m: r.num_quality_shots}) )}
          />
        </WrapItem>
        <WrapItem>
          <TrendMetric 
            value={summary.flubbed_shots[`${timeframe}_${aggregation}`]}
            label='Flubbed Shots'
            trendValues={rounds.map( r => ({m: r.num_flubbed_shots}) )}
          />
        </WrapItem>
        <WrapItem>
          <TrendMetric 
            value={summary.score_to_par[`${timeframe}_${aggregation}`]}
            label='Score to Par'
            trendValues={rounds.map( r => ({m: r.score_to_par}) )}
          />
        </WrapItem>
      </Wrap>
    </VStack>
    <VStack w='100%' spacing={5} paddingTop={5}>
      <HStack w='100%'><Heading size='lg'>Game Areas</Heading></HStack>
      <Wrap w='100%' justify={'center'}>
        <WrapItem>
          <StackedSignChart 
            data={per_round_data.slice(-1*rounds_to_show_map[timeframe])}
            width={bar_width}
          />
        </WrapItem>
        <WrapItem>
          <StatRadar 
            width={radar_width}
            data={per_category_data.slice(-1*rounds_to_show_map[timeframe])}
            seriesName='Strokes Gained'
          />
        </WrapItem>
      </Wrap>
    </VStack>
    <VStack w='100%' spacing={5} paddingTop={5}>
      <Wrap w='100%'>
        <WrapItem>
          <VStack>
            <HStack w='100%'><Heading size='lg'>Approach Shots</Heading></HStack>
            <VerticalBreakdownChart
              width={vert_bar_width} 
              data={approach_data}
              dataKey='sg'
            />
          </VStack>
        </WrapItem>
        <WrapItem>
          <VStack>
            <HStack w='100%'><Heading size='lg'>Putting</Heading></HStack>
            <VerticalBreakdownChart
              width={vert_bar_width} 
              data={putting_data}
              dataKey='sg'
            />
          </VStack>
        </WrapItem>
      </Wrap>
    </VStack>
  </PageContainer>
}