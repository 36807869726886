import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { VStack, Stack, Box, Alert, AlertIcon, AlertDescription,
         FormControl, FormLabel, Input, Button, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { getReset } from '../features/auth';

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from '../components/PageContainer';

export function Reset() {

  const page_title = 'Reset Password'

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  })

  const [email, setEmail] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sign_up = useSelector(state => state.auth.sign_up)

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    dispatch(getReset(email))
  }

  return <PageContainer>
    <VStack justify={'center'} w='100%'>
    <Box
      rounded={'lg'}
      boxShadow={'lg'}
      p={8}
      minW={'400px'}
      marginTop={'50px'}
    >
      <Stack spacing={4}>
            {sign_up.error_message !== ''
              ? <Alert status='error'>
                <AlertIcon />
                <AlertDescription>{sign_up.error_message}</AlertDescription>
              </Alert>
              : null
            }
            {sign_up.reset_requested
              ? <>
                <Text fontSize='3xl' textAlign={'center'}>Check your email for a link to reset your password.</Text>
                <Stack spacing={10}>
                  <Button
                      loadingText='Requesting Reset'
                      isLoading={sign_up.status === 'loading'}
                      bg={'primary.400'}
                      color={'white'}
                      _hover={{
                        bg: 'primary.300',
                      }}
                      onClick={() => navigate('/login')}
                    >
                      Login
                    </Button>
                  </Stack>
              </>
              : <><FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Input 
                    type="email"
                    value={email} 
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormControl>
                <Stack spacing={10}>
                  <Button
                    loadingText='Requesting Reset'
                    isLoading={sign_up.status === 'loading'}
                    bg={'primary.400'}
                    color={'white'}
                    _hover={{
                      bg: 'primary.300',
                    }}
                    onClick={handleFormSubmit}
                  >
                    Reset Password
                  </Button>
                </Stack></>
            }
          </Stack>
    </Box>
    </VStack>
  </PageContainer>
}