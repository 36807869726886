import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/react';
import {
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ComposedChart
} from "recharts";
import { colors, theme } from '../../theme';
import { useNavigate } from 'react-router-dom';

export function StackedSignChart(props) {

  const navigate = useNavigate()

  const formatLegendText = (value, entry, index) => {
    return <Text fontSize={'.9em'} as={'span'}>
      {props.seriesPrefix ? `${props.seriesPrefix} ${value.replaceAll('_', ' ')}` : value.replaceAll('_', ' ')}
    </Text>
  }

  var fill = 800;
  return <ComposedChart
    data={props.data}
    stackOffset='sign'
    width={props.width}
    height={props.height}
  >
    <YAxis stroke={colors.gray[600]} tick={{fontSize: '0.8rem'}}/>
    <XAxis dataKey="name" hide/>
    <Tooltip
      contentStyle={{
        outline: 'none',
        border: 'none',
        borderRadius: '.25rem',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
      }}
      wrapperStyle={{
        border: 'none',
        outline: 'none',
      }}
    />
    <Legend 
      formatter={formatLegendText}
      iconSize={8}
    />
    <ReferenceLine y={0} stroke={colors.gray[600]} />
    {
      props.series.map( (s,i) => {
        
        fill = fill-100;
        return <Bar 
          key={i}
          dataKey={s}
          stackId='stack'
          fill={colors.primary[fill]}
          onClick={e => navigate(e.click_url)}
        />
      })
    }
  </ComposedChart>
}

StackedSignChart.propTypes = {
  data: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  seriesPrefix: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

StackedSignChart.defaultProps = {
  width: 300,
  height: 300,
  series: ['driving', 'approach', 'around_the_green', 'greenside', 'putting']
}