import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';

import {Modal, ModalOverlay, ModalContent, ModalHeader, 
      ModalCloseButton, ModalBody, ModalFooter, 
      Button, Textarea, Text} from '@chakra-ui/react';
import { sendCourseFeedback, clearFeedbackStatus } from '../../features/courses';

export function CourseFeedbackModal(props) {


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFeedbackStatus())
  }, [])

  const [message, setMessage] = useState('');

  const token = useSelector( state => state.auth.token );
  const course_id = useSelector( state => state.rounds.current_round.id)

  const feedback_status = useSelector( state => state.courses.course_feedback_status )

  const handleSubmit = () => {
    dispatch(sendCourseFeedback({
      token: token,
      course_id: course_id,
      message: message,
    }))
  }

  return <Modal isOpen={props.isOpen} onClose={props.onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Something off with this course?  Let us know.</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {feedback_status != 'complete'
          ?
          <Textarea 
            value={message}
            placeholder={'what\'s wrong?'}
            onChange={e => setMessage(e.target.value)}
          />
          : <Text>Thanks, we'll look into it!</Text>
        }
      </ModalBody>
      <ModalFooter>
        {feedback_status != 'complete'
          ? <>
          <Button onClick={handleSubmit} isLoading={feedback_status=='loading'}>Submit</Button>
          <Button variant={'ghost'} onClick={props.onClose}>Cancel</Button>
          </>
          : null
        }
        
      </ModalFooter>
    </ModalContent>
  </Modal>

}

CourseFeedbackModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

CourseFeedbackModal.defaultProps = {
  isOpen: false
}