import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from 'prop-types'
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, 
         DrawerHeader, DrawerOverlay, DrawerFooter, Button } from "@chakra-ui/react";

export function FilterDrawer(props) {

  return <Drawer 
    placement='right'
    isOpen={props.isOpen}
    onClose={props.onClose}
  >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader></DrawerHeader>
      <DrawerBody>
        {props.children}
      </DrawerBody>
      <DrawerFooter>
        <Button onClick={props.onClose}>Close</Button>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
}

FilterDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

FilterDrawer.defaultProps = {
  isOpen: false,
  onClose: null
}