import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/auth/`,
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
  }
});

const routes = {
  createUser: 'users/',
  activateUser: 'users/activation/',
  currentUser: 'users/me/',
  loginUser: 'token/login/',
  logoutUser: 'token/logout/',
  requestPasswordReset: 'users/reset_password/',
  resetPassword: 'users/reset_password_confirm/',
}

export const activateUser = async (payload) => {
  const {data} = await instance.post(
    routes.activateUser,
    {
      uid: payload.uid,
      token: payload.token
    }
  )
  return data;
}

export const createUser = async (payload) => {
  const { data } = await instance.post(
    routes.createUser,
    {
      email: payload.email,
      password: payload.password,
      re_password: payload.re_password
    }
  );
  return data;
}

export const login = async (email, password) => {
  const { data } = await instance.post(
    routes.loginUser,
    { email, password }
  );
  return data;
}

export const requestReset = async (email) => {
  const { data } = await instance.post(
    routes.requestPasswordReset,
    { email}
  );
  return data;
}

export const confirmReset = async (payload) => {
  const { data } = await instance.post(
    routes.resetPassword,
    {
      uid: payload.uid,
      token: payload.token,
      new_password: payload.password,
      re_new_password: payload.re_password
    }
  );
  return data;
}

export const logout = async (token) => {
  const { data } = await instance.post(
    routes.logoutUser,
    { },
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${token}`
      }
    }
  );
  return data;
}

export const getMe = async (token) => {
  const { data } = await instance.get(
    routes.currentUser,
    {
      headers: {
        ...instance.headers,
        'Authorization': `Token ${token}`
      }
    }
  )
  return data;
}