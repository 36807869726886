import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, HStack, Heading, Button, VStack } from "@chakra-ui/react";
import { RoundList } from "../components/RoundList/RoundList";

import { getRounds } from "../features/rounds";
import { roundsSlice } from "../features/rounds";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from "../components/PageContainer";

export function Rounds() {

  const page_title = 'Rounds'

  const dispatch = useDispatch()

  const token = useSelector( state => state.auth.token )
  const rounds = useSelector( state => state.rounds.rounds )

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
    
    dispatch(getRounds({ token }))
    dispatch(roundsSlice.actions.clearCurrentRound())
  }, [])

  return <PageContainer>
    <VStack alignItems={'flex-start'} spacing={4} w='100%'>
      <HStack justifyContent={'space-between'}>
        <Heading>Rounds</Heading>
      </HStack>
      <RoundList rounds={rounds} />
    </VStack>
  </PageContainer>
}