import { useSelector } from "react-redux";

import { Box, VStack } from "@chakra-ui/react";

import { SignUpCard } from "../components/SignUpCard/SignUpCard";
import { useEffect } from "react";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from "../components/PageContainer";

export function Signup() {

  const page_title = 'Sign Up'

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])
  const sign_up = useSelector(state => state.auth.sign_up)

  return <PageContainer>
    <VStack justify={'center'} w='100%'>
      <SignUpCard 
        loading={sign_up.status === 'loading'}
        showConfirmation={sign_up.success}
        errorMessage={sign_up.error_message.message}
      />
    </VStack>
  </PageContainer>
}