import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HStack, VStack, FormControl, FormLabel, RangeSlider,
         RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack,
         Tooltip, Box, Heading, Button, Tag, TagLabel, TagCloseButton, 
         TagLeftIcon, Spinner, Alert, AlertIcon, Modal, ModalOverlay, 
         ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
         Text, Input, InputGroup, InputRightAddon, Skeleton} from '@chakra-ui/react';

import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { Select } from 'chakra-react-select';

import { ScatterChart, Tooltip as ReTooltip, XAxis, YAxis, ResponsiveContainer,
        Scatter } from 'recharts';
         

import {MdGraphicEq} from 'react-icons/md';
import { FiFilter, FiSave, FiXCircle } from 'react-icons/fi';

import mixpanel from 'mixpanel-browser';
import { VIEWED_PAGE } from '../analytics/events';

import { lie_options, useDebounce } from '../utils';

import { PageContainer } from '../components/PageContainer';
import { DataTable } from '../components/DataTable';
import { FilterDrawer } from '../components/FilterDrawer';
import { getAllShotFilters, getAllShots, getAllShotTypes, createAShotFilter } from '../features/shots';
import { TrendMetric } from '../components/TrendMetric';
import { colors } from '../theme';

export function ShotAnalyzer() {
  
  const page_title = 'Shot Analyzer'

  const dispatch = useDispatch();

  const shots_status = useSelector( state => state.shots.status.shots ) 
  const { shots, current, total_pages} = useSelector( state => state.shots )
  
  const shot_filter_status = useSelector( state => state.shots.status.shot_filters)
  const shot_filters = useSelector( state => state.shots.shot_filters)
  const shot_filter_options = shot_filters.map( f => ({value: f.id, label: f.name}) )

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])

  const [filter_open, setFilterOpen] = useState(false)
  const [save_modal_open, setSaveModalOpen] = useState(false)
  const [new_saved_filter_name, setSavedFilterName] = useState('')
  const [shot_filter, setShotFilter] = useState(null)

  const [standard_date, setStandardDate] = useState(365)
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 365));
  const [dates, setDates] = useState([priorDate, today])
  const [distance, setDistance] = useState([0, 700])
  const [lie, setLie] = useState(null)
  const [page_size, setPageSize] = useState(20)

  const get_query_filters = () => ({
    start_date: dates[0].toISOString().split('T')[0],
    end_date: dates[1].toISOString().split('T')[0],
    start_distance: distance[0],
    end_distance: distance[1],
    lie: lie ? lie.map( l => l.value).join() : null,
    page_size: page_size
  })

  useEffect(() => {
    dispatch(getAllShotTypes())
    dispatch(getAllShotFilters())
  }, [])


  const debounced_distance = useDebounce(distance, 300)
  useEffect(() => {
    dispatch(getAllShots({page: 1, filters: get_query_filters()}))
  }, [dates, debounced_distance, lie, page_size, dispatch])

  const standard_date_options = [
    { value: 7, label: 'Last 7 Days' },
    { value: 30, label: 'Last 30 Days' },
    { value: 90, label: 'Last 90 Days' },
    { value: 365, label: 'Last 365 Days' },
    { value: -1, label: 'Custom' },
  ]

  const page_options = [
    { value: 10, label: '10 Shots' },
    { value: 20, label: '20 Shots' },
    { value: 50, label: '50 Shots' },
    { value: 100, label: '100 Shots' },
    { value: 500, label: '500 Shots' },
  ]

  const columns = [
    
    {
      Header: 'Distance',
      accessor: 'distance'
    },
    {
      Header: 'Lie',
      accessor: 'lie'
    },
    {
      Header: 'Strokes Gained',
      accessor: 'strokes_gained'
    },
    {
      Header: 'Course',
      accessor: 'hole.course.name'
    },
    {
      Header: 'Hole',
      accessor: 'hole.number'
    },
    {
      Header: 'Par',
      accessor: 'hole.par'
    },
    {
      Header: 'Date',
      accessor: 'round.date'
    },

  ]

  // special resizing for the SVG graphs
  // allows us to dynamically allocate the height of the element
  const metric_row_ref = useRef(0)
  const [metric_row_height, setMetricRowHeight] = useState(0)
  useEffect(() => {
    setMetricRowHeight(metric_row_ref.current.clientHeight)
    const handleResize = () => {
      setMetricRowHeight(metric_row_ref.current.clientHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  const pct_quality_shots = (shots.map( s => s.is_quality ? 1 : 0).reduce( (a, b) => a + b, 0) / shots.length) * 100
  const pct_bad_shots = (shots.map( s => s.is_flubbed ? 1 : 0).reduce( (a, b) => a + b, 0) / shots.length) * 100

  const getNextPage = () => {
    dispatch(getAllShots({page: current + 1, filters: get_query_filters()}))
  }

  const getPrevPage = () => {
    dispatch(getAllShots({page: current - 1, filters: get_query_filters()}))
  }

  const handleDateRangeChange = (e) => {
   setDates(e)
   setStandardDate(-1)
  }

  const handleUpdateStandardDate = (e) => {
    setStandardDate(e.value)

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - e.value));
    setDates([priorDate, today])
  }

  const handleSelectShotFilter = (e) => {
    
    if (!e) {
      setShotFilter(null)
      resetFilters()
      return
    }
    setShotFilter(e.value)

    const filter = shot_filters.find( f => f.id === e.value || null)
    if (!filter) return

    if (filter.min_distance) setDistance([filter.min_distance, distance[1]])
    if (filter.max_distance) setDistance([distance[0], filter.max_distance])
    if (filter.lies) setLie(filter.lies.map( l => lie_options.find( o => o.value === l)))
    
    if (filter.standard_date_range) handleUpdateStandardDate({value: filter.standard_date_range})
    if (filter.custom_date_min) handleDateRangeChange([new Date(filter.custom_date_min), dates[1]])
    if (filter.custom_date_max) handleDateRangeChange([dates[0], new Date(filter.custom_date_max)])

  }

  const handleCreateShotFilter = () => {
    const filter = {
      name: new_saved_filter_name,
      min_distance: distance[0],
      max_distance: distance[1],
      lies: lie ? lie.map( l => l.value) : null,
      standard_date_range: standard_date,
      custom_date_min: dates[0].toISOString().split('T')[0],
      custom_date_max: dates[1].toISOString().split('T')[0],
    }
    dispatch(createAShotFilter(filter))
  }

  const renderFilterLabels = () => {
    return <HStack spacing={2} alignItems={'flex-start'}>
      {standard_date !== -1 && <Tag size='sm' variant='solid' colorScheme='primary' mr={2} mb={2}>
        <TagLeftIcon as={FiFilter} />
        <TagLabel>
          <strong>date : </strong>
          {standard_date_options.find( o => o.value === standard_date).label}
        </TagLabel>
      </Tag>}
      {standard_date === -1 && <Tag size='sm' variant='solid' colorScheme='primary' mr={2} mb={2}>
        <TagLeftIcon as={FiFilter} />
        <TagLabel>
          <strong>date : </strong>
          {dates[0].toLocaleDateString()} - {dates[1].toLocaleDateString()}
        </TagLabel>
      </Tag>}
      {distance[0] !== 0 && <Tag size='sm' variant='solid' colorScheme='primary' mr={2} mb={2}>
        <TagLeftIcon as={FiFilter} />
        <TagLabel>
          <strong>min : </strong>
          {distance[0]} yards
        </TagLabel>
        <TagCloseButton onClick={() => setDistance([0, distance[1]])} />
      </Tag>}
      {distance[1] !== 700 && <Tag size='sm' variant='solid' colorScheme='primary' mr={2} mb={2}>
        <TagLeftIcon as={FiFilter} />
        <TagLabel>
          <strong>max : </strong>
          {distance[1]} yards
        </TagLabel>
        <TagCloseButton onClick={() => setDistance([distance[0], 700])} />
      </Tag>}
      {lie && <Tag size='sm' variant='solid' colorScheme='primary' mr={2} mb={2}>
        <TagLeftIcon as={FiFilter} />
        <TagLabel>
          <strong>lie : </strong>
          {lie ? lie.map( l => l.label).join(', ') : 'All'}
        </TagLabel>
        <TagCloseButton onClick={() => setLie(null)}/>
      </Tag>}
    </HStack>
  }

  const resetFilters = () => {
    setStandardDate(365)
    setDistance([0, 700])
    setLie(null)
    setPageSize(100)

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 365));
    setDates([priorDate, today])
  }

  const renderFilters = () => {
    return <VStack 
      w='100%'
      alignItems='flex-start'
      spacing={5}
    >
      <FormControl zIndex={100}>
        <FormLabel>Saved Filters</FormLabel>
        <Select 
          options={shot_filter_options}
          value={shot_filter_options.find( f => f.value === shot_filter) || null}
          onChange={e => handleSelectShotFilter(e)}
          isClearable
        />
      </FormControl>

      <FormControl>
        <FormLabel paddingBottom={7}>Distance</FormLabel>
        <HStack>
          <InputGroup>
            <Input value={distance[0]} onChange={e => setDistance([Number(e.target.value), distance[1]])}/>
            <InputRightAddon children='yds' />
          </InputGroup>
          <InputGroup>
            <Input value={distance[1]} onChange={e => setDistance([distance[0], Number(e.target.value)])}/>
            <InputRightAddon children='yds' />
          </InputGroup>
        </HStack>
        <Box paddingX={5}>
          <RangeSlider
            aria-label={['min', 'max']} 
            value={distance}
            min={0}
            max={700}
            colorScheme='primary'
            onChange={setDistance}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0}>
              <Box color='primary' as={MdGraphicEq} />
            </RangeSliderThumb>
            <RangeSliderThumb index={1}>
              <Box color='primary' as={MdGraphicEq} />
            </RangeSliderThumb>
          </RangeSlider>
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>Lie</FormLabel>
        <Select 
          value={lie}
          onChange={setLie}
          options={lie_options}
          isMulti
          isClearable
        />
      </FormControl>

      <FormControl>
        <FormLabel >Date Range</FormLabel>
        <VStack>
          <Select 
            options={standard_date_options}
            value={standard_date_options.find( o => o.value === standard_date)}
            onChange={e => handleUpdateStandardDate(e)}
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: '100%'
              })
            }}
          />
          <RangeDatepicker 
            selectedDates={dates}
            onDateChange={handleDateRangeChange}
          />
        </VStack>
      </FormControl>

      <FormControl>
        <FormLabel>Number of Results</FormLabel>
        <Select 
          options={page_options}
          value={page_options.find( o => o.value === page_size)}
          onChange={e => setPageSize(e.value)}
        />
      </FormControl>

    </VStack>
  }

  const renderSaveModal = () => {
    return <Modal isOpen={save_modal_open} onClose={() => setSaveModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Shot Filter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={5}>You can save this filter to quickly access it later.</Text>
          <FormControl>
            <FormLabel>Filter Name</FormLabel>
            <Input 
              placeholder='What do you want to call it?'
              value={new_saved_filter_name}
              onChange={e => setSavedFilterName(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} leftIcon={<FiXCircle />} onClick={() => setSaveModalOpen(false)}>Cancel</Button>
          <Button 
            leftIcon={<FiSave />} 
            colorScheme='primary' 
            isLoading={shot_filter_status === 'loading'} 
            loadingText={'Saving'}
            onClick={() => handleCreateShotFilter()}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  }

  return <PageContainer> 
    <VStack w='100%' spacing={10} justifyContent={'flex-start'}>
      
      {renderSaveModal()}

      <FilterDrawer 
        isOpen={filter_open}
        onClose={() => setFilterOpen(false)}
      >
        {renderFilters()}
      </FilterDrawer>

      <HStack w='100%' justify='space-between'>
        
        <Heading>Shot Analyzer</Heading>
        
        <HStack>
          {shots_status === 'loading' ?  <Spinner color='primary.400'/> : null}
          <Tooltip label='access this filter later' color={'white'}>
            <Button leftIcon={<FiSave />} onClick={() => setSaveModalOpen(true)}>Save Filter</Button>
          </Tooltip>
          <Button leftIcon={<FiFilter />} onClick={() => setFilterOpen(!filter_open)}>Edit Filter</Button>
        </HStack>
      </HStack>

      <HStack w={'100%'}>
        {renderFilterLabels()}
      </HStack>

      {total_pages > 1 
        ? <Alert status='warning'>
          <AlertIcon />
          Your current filter returned multiple pages of data.  Please refine your search or increase the number of results to view the complete dataset.
        </Alert>
        : null
      }

      <HStack justify={'flex-start'} w='100%' ref={metric_row_ref}>
        <TrendMetric
          label='Average SG'
          value={shots.map( s => Number(s.strokes_gained)).reduce( (a, b) => a + b, 0) / shots.length}
          trendValues={shots.map( s => ({ m: Number(s.strokes_gained)}))}
          loading={shots_status === 'loading'}
        />
        <TrendMetric
          label='% Quality Shots'
          value={pct_quality_shots}
          units='%'
          decimals={1}
          trendValues={shots.map( s => ({ m: s.is_quality ? 1 : 0}))}
          loading={shots_status === 'loading'}
        />
        <TrendMetric
          label='% Bad Shots'
          value={pct_bad_shots}
          units='%'
          decimals={1}
          trendValues={shots.map( s => ({ m: s.is_flubbed ? 1 : 0}))}
          loading={shots_status === 'loading'}
        />
        
        <Box height={metric_row_height} flexGrow={4}>
          <ResponsiveContainer width='100%' height='100%'>
            <ScatterChart
              width={400}
              height={400}
              keu={shots.length}
            >
              <XAxis 
                dataKey='distance' 
                name='distance' 
                type='number' 
                unit=' yds'
                height={15}
                domain={['auto', 'auto']}
                tick={{
                  fontSize: 10,
                }}
              />
              <YAxis 
                dataKey='strokes_gained' 
                name='strokes gained' 
                width={60}
                type='number'
                tick={{
                  fontSize: 10,
                }}
                label={{
                  value: 'strokes gained',
                  fontSize: 10,
                  color: colors.gray['700'],
                  angle: -90,
                  position: 'insideleft'
                }}
              />
              <ReTooltip cursor={{ strokeDasharray: '3 3' }} />
              <Scatter
                name='shots'
                data={shots
                  .map( s => ({ distance: Number(s.distance), strokes_gained: Number(s.strokes_gained)}))
                  .sort( (a, b) => a.distance - b.distance)
                }
                lineType='fitting'
                line={{
                  stroke: colors.primary['100']
                }}
                fill={colors.primary['500']}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </Box>
      </HStack>

      <DataTable 
        columns={columns} 
        data={shots} 
        currentPage={current}
        getNext={current !== total_pages ? getNextPage : null}
        getPrev={current !== 1 ? getPrevPage : null}
        totalPages={total_pages}
        loading={shots_status === 'loading'}
      />
    
    </VStack>

  </PageContainer>
}