import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import mixpanel from 'mixpanel-browser'
import { VIEWED_PAGE } from '../analytics/events'

import { Box, Stack, Heading, Text, Tag, HStack, color } from "@chakra-ui/react"

import { PageContainer } from "../components/PageContainer"
import { getPage } from '../features/blog'

export function BlogPage() {
  
  const { slug } = useParams()
  
  const page_title = slug.replaceAll('-', ' ')
  const media_host = process.env.REACT_APP_API_HOST

  const dispatch = useDispatch()

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  })

  useEffect(() => {
    dispatch(getPage({slug}))
  }, [slug, dispatch])

  const page = useSelector(state => state.blog.current_page)

  const renderTags = page ? page.items[0].tags.map(tag => <Tag key={tag}>{tag}</Tag>) : null
  
  const getBodyContent = () => {
    return page.items[0].body.replace(`/media/images`, `${media_host}/media/images`)
  }

  return <PageContainer>
    <Stack as={Box} textAlign={'left'} spacing={{ base: 2, md: 4 }} py={{ base: 10 }}>
      <Heading>{page ? page.items[0].title : 'Page Not Found'}</Heading>
      <Text  as='i' fontSize='sm' color='gray.600'>{page ? `published on ${page.items[0].date} by ${page.items[0].author.alias}` : null}</Text>
      <HStack>
        {renderTags}
      </HStack>
      <Box 
        backgroundColor={'secondary.50'} 
        p={{base: 5}} 
        borderRadius='md'
      >
        <Heading as='h2' size='md'>Introduction</Heading>
        <Text as='i'>{page ? page.items[0].intro : null}</Text>
      </Box>
      <Box 
        p={{base: 5}} 
        sx={{
          '.blog-post-body': {
            fontSize: '1.1rem',
          },
          '.blog-post-body h2': {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '.5rem',
          }
        }}
      >
        <div className='blog-post-body' dangerouslySetInnerHTML={{__html: page ? getBodyContent() : null}} />
      </Box>
    </Stack>
  </PageContainer>
}