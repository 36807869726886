import { useNavigate, useParams } from "react-router-dom";

import { Flex, Text, Box, Stack, Spinner, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateNewUser } from "../features/auth";
import { CheckCircleIcon } from "@chakra-ui/icons";

import mixpanel from "mixpanel-browser";
import { COMPLETED_SIGN_UP, VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from "../components/PageContainer";

export function Activate() {
  
  const page_title = 'Activate Account'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [activated, toggleActivated] = useState(false)

  const sign_up = useSelector(state => state.auth.sign_up)
  const { uid, token } = useParams()
  
  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  })

  useEffect(() => {
    dispatch(activateNewUser({uid, token}))
  }, [uid, token, dispatch])


  if (sign_up.activated && ! activated) {
    mixpanel.track(COMPLETED_SIGN_UP.name)
    toggleActivated(true)
  }

  return <PageContainer>
    <Box
      rounded={'lg'}
      boxShadow={'lg'}
      p={8}
      minW={'400px'}
      marginTop={'50px'}
    >
      <Stack spacing={4} alignItems='center'>
        {sign_up.activated
          ? <>
            <CheckCircleIcon color={'primary.400'}  boxSize='3em'/>
            <Text fontSize={'3xl'} textAlign='center'>You're all set!</Text>
            <Button
              size="lg"
              bg={'primary.400'}
              color={'white'}
              onClick={() => navigate('/login')}
              _hover={{
                bg: 'primary.300',
              }}>
              Login
            </Button>
            </>
          : <>
            <Spinner/>
            <Text fontSize={'3xl'} textAlign='center'>Confirming email address and <br />activating your account.</Text>
            </>
        }
      </Stack>
    </Box>
  </PageContainer>
}