import PropTypes from 'prop-types'
import { Stat, StatArrow, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import { colors } from '../../theme';

export function BasicMetric(props) {
  
  return <Stat 
    w={{ base: 150}} 
    h={150} 
    bg={colors.gray[100]} 
    p={{base: 2, md: 4}}
  >
    <StatLabel color={'gray.500'} minW='100px'>{props.label}</StatLabel>
    <StatNumber fontSize={props.size}>{props.value}</StatNumber>
    {props.secondaryValue
      ? <StatHelpText>
          {props.secondaryArrow !== 'none' 
            ? <StatArrow type={props.secondaryArrow === 'up' ? 'increase' : 'decrease'}/> 
            : null
          }
          {`${props.secondaryValue.toFixed(2)} ${props.secondaryUnits}`}
        </StatHelpText>
      : null
    }
  </Stat>
}

BasicMetric.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xl', '2xl', '3xl', '4xl', '5xl']),
  secondaryValue: PropTypes.number,
  secondaryUnits: PropTypes.string,
  secondaryArrow: PropTypes.oneOf(['up', 'down', 'none'])
}

BasicMetric.defaultProps = {
  size:   '4xl'
}