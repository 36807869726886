import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'
import { Stat, StatArrow, StatHelpText, StatLabel, StatNumber, VStack, Box, Skeleton, SkeletonText } from '@chakra-ui/react';
import { AreaChart, Area } from 'recharts';
import { colors } from '../../theme';

export function TrendMetric(props) {
  
  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  })

  return <VStack 
    minw={props.minW} 
    alignItems={'flex-start'} 
    w={150} 
    h={150} 
    bg={colors.gray[100]}
    p={{base: 2, md: 4}}
  >
    <VStack w={'100%'} ref={ref} spacing={1} alignItems={'flex-start'}>
      <Stat>
        <StatLabel color={'gray.500'} minW='100px'>{props.label}</StatLabel>
        <StatNumber fontSize={props.size} >
          <Skeleton isLoaded={!props.loading} w={width}>
            <Box>
            {`${props.value.toFixed(props.decimals)}${props.units}`}
            </Box>
          </Skeleton>
        </StatNumber>
        {props.secondaryValue
          ? <StatHelpText>
              {props.secondaryArrow !== 'none' 
                ? <StatArrow type={props.secondaryArrow === 'up' ? 'increase' : 'decrease'}/> 
                : null
              }
              {`${props.secondaryValue} ${props.secondaryUnits}`}
            </StatHelpText>
          : null
        }
      </Stat>
      <Skeleton isLoaded={!props.loading}>
      <AreaChart
        data={props.trendValues}
        width={width}
        height={45}
      >
          <Area type='monotone' dataKey='m' stroke={colors.primary[400]} fill={colors.primary[200]} />
      </AreaChart>
      </Skeleton>
    </VStack>
  </VStack>
}

TrendMetric.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  units: PropTypes.string,
  trendValues: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.oneOf(['xl', '2xl', '3xl', '4xl', '5xl']),
  minW: PropTypes.number,
  decimals: PropTypes.number,
  loading: PropTypes.bool,
}

TrendMetric.defaultProps = {
  size:   '4xl',
  trendValues: [],
  minW: 150,
  decimals: 2,
  units: '',
  decimals: 2,
  loading: false,
}