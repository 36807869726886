import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Box, VStack, Heading, HStack, Button } from "@chakra-ui/react";
import { Select } from 'chakra-react-select';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';

import { getCourses } from "../features/courses";
import { createNewRound, roundsSlice } from "../features/rounds";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from "../components/PageContainer";
import { CourseSelect } from "../components/CourseSelect";

export function AddRound() {
  
  const page_title = 'Add Round'

  const dispatch = useDispatch();

  const [course, setCourse] = useState(null)
  const [tee, setTee] = useState(null)
  const [date, setDate] = useState(new Date());

  const token = useSelector( state => state.auth.token );
  const user_id = useSelector( state => state.auth.user.id)

  const courses = useSelector( state => state.courses.courses)
  const current_course = useSelector( state => state.courses.current_course)
  const new_round = useSelector( state => state.rounds.current_round)

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])

  useEffect(() => {
    dispatch(roundsSlice.actions.clearCurrentRound())
    dispatch(getCourses({ token }))
    
    return () => {
      dispatch(roundsSlice.actions.clearCurrentRound())
    }
  }, [dispatch, token])

  const getTees = () => {

    if (!current_course) return null

    return current_course
      .course_tees
      .map( t => ({ value: t.id, label: `${t.name} - ${t.gender}` }))

  }

  const handleCreateRound = () => {
    dispatch(createNewRound({
      user_id,
      course: current_course.id,
      tee: tee.value,
      date: date.toISOString().split('T')[0],
      token
    }))
  }

  if (new_round) return <Navigate to={`/round/${new_round.id}/edit`}/>

  return <PageContainer>
    <VStack
      w='100%'
      spacing={4}
    >
      <Heading m='2'>New Round</Heading>
      <Box w='30%'>
        <CourseSelect />
      </Box>
      <Box w='30%'>
        <Select
          isDisabled={!current_course}
          value={tee}
          onChange={setTee}
          options={getTees()}
        />
      </Box>
      <Box w='30%'>
        <SingleDatepicker 
          date={date}
          onDateChange={setDate}
        />
      </Box>
      <Button 
        disabled={!(current_course && tee && date)}
        onClick={handleCreateRound}
      >Enter Shots</Button>
    </VStack>
    <HStack>

    </HStack>
  </PageContainer>
}