import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUserSummary } from "./api";

const initialState = {
  status: 'idle',
  summary: {},
  error_message: ''
}

export const getSummary = createAsyncThunk(
  'stats/getSummary',
  async (payload) => {
    return getUserSummary(payload)
  }
)

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},  
  extraReducers: builder => {
    builder
      .addCase(getSummary.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getSummary.fulfilled, (state, action) => {
        state.status = 'idle'
        state.error_message = ''
        state.summary = action.payload
      })
      .addCase(getSummary.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'could not fetch summary stats'
      })
  }
})