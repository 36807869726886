import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";
import { ADDED_ROUND, DELETED_ROUND, EDITED_SHOTS } from "../../analytics/events";

import { getAllRounds, createRound, updateShots, getARound, deleteARound } from "./api";

const initialState = {
  status: 'idle',
  error_message: '',
  next_page: null,
  previous_page: null,
  rounds: [],
  current_round: null,
  current_round_shots: [],
  current_round_hole_status: new Array(18).fill('nodata')
}

export const getRounds = createAsyncThunk(
  'rounds/getRounds',
  async (payload) => {
    return getAllRounds(payload.token)
  }
)

export const getRound = createAsyncThunk(
  'rounds/getRound',
  async (payload) => {
    return getARound(payload)
  }
)

export const createNewRound = createAsyncThunk(
  'rounds/createNewRound',
  async (payload) => {
    return createRound(payload)
  }
)

export const deleteRound = createAsyncThunk(
  'rounds/deleteRound',
  async (payload) => {
    return deleteARound(payload)
  }
)

export const updateHoleShots = createAsyncThunk(
  'rounds/updateHoleShots',
  async (payload) => {
    return updateShots(payload)
  }
)

export const roundsSlice = createSlice({
  name: 'rounds',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error_message = ''
    },
    clearCurrentRound: (state) => {
      state.current_round = initialState.current_round
      state.current_round_hole_status = initialState.current_round_hole_status
      state.current_round_shots = initialState.current_round_shots
    },
    setHoleDirtyFlag: (state, action) => {
      state.current_round_hole_status = state.current_round_hole_status
        .map( (s,i) => i === action.payload ? 'unsaved' : s)
    }
  },  
  extraReducers: builder => {
    builder
      .addCase(deleteRound.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(deleteRound.fulfilled, (state) => {
        state.status = 'idle'
        state.error_message = ''
        mixpanel.track(DELETED_ROUND.name)
      })
      .addCase(deleteRound.rejected, (state, action) => {
        state.status = 'idle'
        state.error_message = 'unable to delete round, try again later'
      })
      .addCase(getRounds.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(getRounds.fulfilled, (state, action) => {
        state.status = 'idle'
        state.rounds = action.payload.results
        state.next_page = action.payload.next
        state.previous_page = action.payload.previous
      })
      .addCase(getRounds.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to fetch rounds, try again later'
      })
      .addCase(createNewRound.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
      })
      .addCase(createNewRound.fulfilled, (state, action) => {
        state.status = 'idle'
        state.error_message = ''
        state.current_round = action.payload
        mixpanel.track(ADDED_ROUND.name)
      })
      .addCase(createNewRound.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to create round, try again later'
      })
      .addCase(getRound.pending, (state) => {
        state.status = 'loading'
        state.error_message = ''
        state.current_round = initialState.current_round
      })
      .addCase(getRound.fulfilled, (state, action) => {
        state.status = 'idle'
        state.error_message = ''
        state.current_round = action.payload
      })
      .addCase(getRound.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to get round, try again later'
      })
      .addCase(updateHoleShots.pending, (state, action) => {
        state.status = 'loading'
        state.error_message = ''
        state.current_round_hole_status = state.current_round_hole_status
          .map( (s,i) => s === 'unsaved' ? 'saving' : s)
        mixpanel.track(EDITED_SHOTS.name)
      })
      .addCase(updateHoleShots.fulfilled, (state, action) => {
        state.status = 'idle'
        state.error_message = ''
        state.current_round_shots = action.payload.shots
        state.current_round_hole_status = state.current_round_hole_status
          .map( (s,i) => i === action.payload.hole.number-1 ? 'saved' : s)
      })
      .addCase(updateHoleShots.rejected, (state) => {
        state.status = 'idle'
        state.error_message = 'unable to update shots, try again later'
      })
  }
})

export const { clearErrors } = roundsSlice.actions

export default roundsSlice.reducer