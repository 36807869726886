import { useEffect } from "react";
import { useStore } from "react-redux";
import { Navigate } from "react-router-dom";

import { Box, VStack } from "@chakra-ui/react";

import { SignInCard } from "../components/SignInCard/SignInCard";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageContainer } from "../components/PageContainer";

export function Login() {
  
  const page_title = 'Activate Account'

  const store = useStore()
  const { auth } = store.getState()

  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])

  if (auth.isLoggedIn) return <Navigate to='/'/>

  return <PageContainer>
    <VStack justify={'center'} w='100%'>
      <SignInCard />
    </VStack>
  </PageContainer>
}