import PropTypes from 'prop-types'
import { PolarGrid, RadarChart, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import { colors } from '../../theme';

export function StatRadar(props) {

  const WIDTH_CONSTANT = 0.95

  const get_label_offset = (x, text_length) => {
    
    const width = props.width * WIDTH_CONSTANT

    if (x < 50) return x / 2
    else if (x > (width-50)) return -1 * (width-x)
    else return 0;

  }

  const renderCustomLabel = ({ payload, x, y, textAnchor, stroke, radius }) => {

    return <text
      radius={radius}
      x={x}
      y={y}
      className="recharts-text recharts-polar-angle-axis-tick-value"
      textAnchor={textAnchor}
      stroke={colors.gray[600]}
    >
      <tspan 
        x={x}
        dx={get_label_offset(x)} 
        dy='0em' 
        fontSize={'0.6rem'}
      >
        {payload.value.replaceAll('_', ' ')}
      </tspan>
    </text>
  }

  return <RadarChart
    width={props.width * WIDTH_CONSTANT}
    height={props.height}
    data={props.data}
  >
    <PolarGrid gridType='circle'/>
    <PolarAngleAxis 
      dataKey='category' 
      tick={renderCustomLabel}
    />
    <PolarRadiusAxis />
    <Radar 
      name={props.seriesName}
      dataKey={'value'}
      stroke={colors.primary[400]}
      fill={colors.primary[200]}
      fillOpacity={0.6}
    />

  </RadarChart>
}

StatRadar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  seriesName: PropTypes.string.isRequired,
}

StatRadar.defaultProps = {
  width: 300,
  height: 300
}