import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/blog/v1/`,
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
  }
});

const routes = {
  pages: 'pages/',
}

export const getAllPages = async () => {
  const { data } = await instance.get(
    routes.pages,
  );
  return data;
}

export const getAPage = async (payload) => {
  const { data } = await instance.get(
    routes.pages,
    {
      params: {
        slug: payload.slug,
        fields: '*',
        type: 'blog.BlogPage'
      }
    }
  );
  return data;
}

