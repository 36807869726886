import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box, VStack, Input, Heading, HStack, 
         TableContainer, Thead, Th,Tr, Table, Tbody, Td, Text, 
         Fade, IconButton, Spinner, Tooltip, Button, Icon } from "@chakra-ui/react";
import { EditIcon, CloseIcon, WarningIcon, CheckIcon, WarningTwoIcon, ViewIcon } from "@chakra-ui/icons";
import {
  FiFlag
} from 'react-icons/fi';

import { RoundForm } from "../components/RoundForm";

import { lie_options } from "../utils";

import { getCourses } from "../features/courses";
import { updateHoleShots, getRound, roundsSlice } from "../features/rounds";

import mixpanel from "mixpanel-browser";
import { VIEWED_PAGE } from "../analytics/events";
import { PageLoader } from "../components/PageLoader";
import { PageContainer } from "../components/PageContainer";
import { CourseFeedbackModal } from "../components/CourseFeedbackModal";

export function EditRound() {

  const page_title = 'Edit Round'

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector( state => state.auth.token );
  const { round_id } = useParams()
  
  useEffect(() => {
    document.title = page_title
    mixpanel.track(VIEWED_PAGE.name, {
      page_title
    })
  }, [])
  
  useEffect(() => {
    dispatch(getCourses({ token }))
    dispatch(getRound({ token, round: round_id }))
  }, [dispatch, token, round_id])

  const [shot_entries, setShotEntries] = useState()
  const [editing_round, toggleEditRound] = useState(false)
  const [shots_dirty, toggleShotsDirty] = useState(false)
  const [modalOpen, toggleModalOpen] = useState(false)

  const updateSpecificEntry = (cell_id, value) => {

    const m = cell_id.split('|').map( c => parseInt(c))

    let newShotEntries = [...shot_entries]
    
    if (!newShotEntries[m[0]][m[1]]) {
      newShotEntries[m[0]][m[1]] = ['','', false]
    }

    // makes sure the shot length is a number
    if (m[2] === 0 && value !== '') {
      value = parseInt(value)
      if (isNaN(value)) {
        newShotEntries[m[0]][m[1]][2] = true;
        value = '';
      } else {
        newShotEntries[m[0]][m[1]][2] = false;
      }
    } 

    // filter out lies that don't match spec
    if(m[2] === 1 && !lie_options.map( l => l.value.toUpperCase()).includes(value.toUpperCase())) {
      value = ''
    }

    // set lie to uppercase
    if (m[2] === 1) value = value.toUpperCase()

    // set the new shot length/lie
    newShotEntries[m[0]][m[1]][m[2]] = value
    
    // filter out shots with no length
    newShotEntries[m[0]] = newShotEntries[m[0]]
      .filter( e => e[0] !== '')

    // completely replace old shot matrix
    setShotEntries(newShotEntries)

    // update state of hole
    dispatch(roundsSlice.actions.setHoleDirtyFlag(m[0]))

  }

  const renderHeader = () => {

    return <HStack justifyContent={'space-between'}>
      <HStack minW={'50%'}>
        <Box 
          w='100%' 
          display={'flex'} 
          alignItems='center'
        >
          {editing_round
            ? <RoundForm 
              courses={courses}
              course={current_round.course}
              date={current_round.date}
              tee={current_round.tee}
            />
            : <Heading size='lg'>{`${current_round.course.name}  (${current_round.date})`}</Heading>
          }
          <IconButton 
            aria-label={'edit'} 
            variant={'ghost'} 
            icon={editing_round ? <CloseIcon /> : <EditIcon />}
            onClick={() => toggleEditRound(!editing_round)}
            size={'xs'} 
            marginLeft={'10px'}   
          />
        </Box>
      </HStack>
      <HStack>
        <IconButton 
          variant='ghost' 
          icon={<Icon as={FiFlag}/>} 
          onClick={() => toggleModalOpen(!modalOpen)}
          colorScheme='highlight'
        />
        <IconButton 
          variant='ghost' 
          icon={<CloseIcon />} 
          onClick={() => navigate('/rounds')}
          colorScheme='highlight'
        />
        <Button 
          icon={<ViewIcon />} 
          variant='outline' 
          colorScheme={'primary'}
          disabled={shots_dirty}
          onClick={() => navigate(`/round/${current_round.id}/view`)}
        >Analyze Round</Button>
      </HStack>
    </HStack>
  }

  const renderShotEntry = (hole) => {

    const current_round_holes = [...current_round.course.course_holes]
      .filter( h => h.tee === current_round.tee.id)
      .sort( (a,b) => a.number - b.number)

    return current_round_holes.map( (h,k) => {

      if (!shot_entries) return null
 

      const shotInputs = () => {
        let inputs = [];
        for (let i=0; i<=shot_entries[k].length; i++) {

          const isParThreeTee = (h.par === 3) && (i === 0) && (shot_entries[k][i][0] === h.length)

          inputs.push(
            <Fade key={i} in={true}>
              <HStack spacing={0} border='1px solid lightgray' >
                <Input 
                  size={'xs'} 
                  fontSize={'9'}
                  w='60%'
                  value={shot_entries[k][i] ? shot_entries[k][i][0] : ' '}
                  border='none'
                  id={`${k}|${i}|0`}
                  onChange={e => updateSpecificEntry(e.target.id, e.target.value)}
                  isInvalid={shot_entries[k][i] ? shot_entries[k][i][2] : false}
                  borderRadius={0}
                  backgroundColor={isParThreeTee ? 'highlight.300' : null}
                  p='5px'
                />
                <Input 
                  size={'xs'}
                  fontSize={'9'} 
                  w='40%' 
                  value={shot_entries[k][i] ? shot_entries[k][i][1] : ' '}
                  border='none'
                  id={`${k}|${i}|1`} 
                  onChange={e => updateSpecificEntry(e.target.id, e.target.value)}
                  isInvalid={shot_entries[k][i] && shot_entries[k][i][0] && !shot_entries[k][i][1]}
                  textTransform={'uppercase'}
                  borderRadius={0}
                  onBlur={() => saveHole(k, h.id)}
                  p='3px'
                />           
              </HStack>
            </Fade>
          )
        }
        return inputs
      }

      return <Td key={k} overflow='visible' m={0} p='1' style={{ verticalAlign: 'top' }}>
        <VStack>
          {shotInputs()}
        </VStack>
      </Td>
    })
  }

  const renderTable = () => {

    const current_round_holes = [...current_round.course.course_holes]
      .filter( h => h.tee === current_round.tee.id)
      .sort( (a,b) => a.number - b.number)

    const course_par = current_round_holes.reduce( (par, h) => par + h.par, 0)
    const total_score = shot_entries.reduce((score, h) => score + h.length, 0)

    return <TableContainer
      overflow={'auto'}
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Hole</Th>
            {current_round_holes.map( (h, k) => 
              <Th 
                key={k}
                style={{ textAlign: 'center' }}
              >{h.number}</Th>)}
              <Th borderLeft={'1px solid #E2E8F0'} ></Th>
          </Tr>
          <Tr>
            <Th>Par</Th>
            {current_round_holes.map( (h, k) => 
              <Th 
                key={k}
                style={{ textAlign: 'center' }}
              >{h.par}</Th>)}
            <Th 
              style={{ textAlign: 'center' }} 
              borderLeft={'1px solid #E2E8F0'}
            >{course_par}</Th>
          </Tr>
          <Tr>
            <Th>Score</Th>
            {shot_entries
              ? current_round_holes.map( (h,k) => 
                <Th 
                  key={k}
                  style={{ textAlign: 'center' }}
                  color={() => {
                    if (shot_entries[k].length > h.par) {
                      return 'accent.600'
                    } else if (shot_entries[k].length < h.par) {
                      return 'primary.600'
                    } 
                  }}
                  backgroundColor={() => {
                    if (shot_entries[k].length > h.par) {
                      return 'accent.200'
                    } else if (shot_entries[k].length < h.par) {
                      return 'primary.200'
                    } 
                  }}
                  transitionProperty={'background-color, color'}
                  transitionDuration={'normal'}
                >{shot_entries[k].length}</Th>)
              : ''
            }
            <Th 
              borderLeft={'1px solid #E2E8F0'}
              style={{ textAlign: 'center' }}
              color={() => {
                if (total_score > course_par) {
                  return 'accent.600'
                } else if (total_score < course_par) {
                  return 'primary.600'
                } 
              }}
              backgroundColor={() => {
                if (total_score > course_par) {
                  return 'accent.200'
                } else if (total_score < course_par) {
                  return 'primary.200'
                } 
              }}
              transitionProperty={'background-color, color'}
              transitionDuration={'normal'}
            >{total_score}</Th>
          </Tr>
          <Tr>
            <Th>Status</Th>
            {hole_status.map( (h, k) => {
              if (h === 'unsaved') {
                return <Th key={k}>
                  <Tooltip label='Move to next hole to save' color='white'  placement='top'>
                    <WarningIcon color='orange.300'/>
                  </Tooltip>
                </Th>
              }else if (h === 'saving') {
                return <Th key={k}>
                  <Spinner size='xs' color='primary.200'/>
                </Th>
              } else if (h === 'saved') {
                return <Th key={k}>
                  <CheckIcon size='xs' color='primary.200'/>
                </Th>
              } else if (h === 'error') {
                return <Th key={k}>
                  <Tooltip label='Unable to save shot' color='white'  placement='top'>
                    <WarningTwoIcon size='xs' color='accent.200'/>
                  </Tooltip>
                </Th>
              }
              return <Th key={k}>
                <Tooltip label='Awaiting shot entry' color='white' placement='top'>
                  <WarningIcon color='gray.300'/>
                </Tooltip>
              </Th>
            })}
            <Th borderLeft={'1px solid #E2E8F0'} />
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td style={{ verticalAlign: 'top' }}>
              <Text fontSize={'xs'} fontWeight={'semibold'} textTransform='uppercase'>Shots</Text>
            </Td>
            {renderShotEntry()}
            <Td borderLeft={'1px solid #E2E8F0'} >
              <VStack align={'flex-start'}>
                <Text fontSize={'sm'} fontWeight='bold'>Key</Text>
                <Text fontSize={'xs'}>
                  {lie_options.map( (l, k) => <span key={k} >{`${l.value} = ${l.label}`}<br /></span>)}
                </Text>
                <Text fontSize={'xs'}>Enter putts in feet<br /> and all other shots<br /> in yards.</Text>
              </VStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>

  }

  const saveHole = (hole_index, hole_id) => {

    // check that all the fields are populated

    if (shot_entries[hole_index].some( e => e[1] === '')) return

    dispatch(updateHoleShots({
      token,
      hole: hole_id,
      round: current_round.id,
      shots: shot_entries[hole_index].map( (s, i) => ({
        shot_number: i+1,
        distance: s[0],
        lie: s[1]
      }))
    })).then(( () => toggleShotsDirty(false)))
  }

  // check to ensure necessary data is loaded
  const current_round = useSelector( state => state.rounds.current_round)
  const courses = useSelector( state => state.courses.courses)
  const hole_status = useSelector( state => state.rounds.current_round_hole_status)

  if (!current_round) return <PageLoader />
  if (courses.length < 1) return <PageLoader />

  // add initial shot values to shot entries
  if (!shot_entries) {
    setShotEntries([...current_round.course.course_holes]
      .filter( h => h.tee === current_round.tee.id )
      .sort( (a,b) => a.number - b.number)
      .map( h => [[h.length, 'T', false]])
    )
  } 
  // once the current round loads, update the table values
  else if (current_round.round_shots && !shots_dirty) {
    let merged_shots = [...shot_entries]
    current_round.round_shots.forEach( (s, i) => {
      const hole_index = current_round.course.course_holes.find( h => h.id === s.hole).number-1
      merged_shots[hole_index][s.shot_number-1] = [s.distance, s.lie, false]
    })
  }
  if (!shot_entries) return <PageLoader />

  return <PageContainer>
    <CourseFeedbackModal 
      isOpen={modalOpen}
      onClose={() => toggleModalOpen(!modalOpen)}  
    />
    <VStack
      w='100%'
      spacing={4}
      align={'left'}
    >
      {renderHeader()}
      <Box
      >
        {renderTable()}
      </Box>
    </VStack>
  </PageContainer>
}